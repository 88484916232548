import React from "react";

function HomeBests(){
    return (
        <div className="homeBestsWrap">
            <div className="homeBestsItem">
              <i className="fa fa-truck" aria-hidden="true"></i>
              <div className="homeBestsItemText">
                  <h4>БЕСПЛАТНАЯ ДОСТАВКА</h4>
                  <span>По Алматы при покупке <br/>от 20 000〒</span>
              </div>
            </div>
            <div className="homeBestsItem">
            <i className="fa fa-gift" aria-hidden="true"></i>
              <div className="homeBestsItemText">
                  <h4>скидки и акции</h4>
                  <span>Не упустите шанс купить товар по сниженной цене</span>
              </div>
            </div>
            <div className="homeBestsItem">
              <i className="fa fa-reply" aria-hidden="true"></i>
              <div className="homeBestsItemText">
                  <h4>возврат товара</h4>
                  <span>В течении 14 дней</span>
              </div>
            </div>
            <div className="homeBestsItem">
              <i className="fa fa-truck" aria-hidden="true"></i>
              <div className="homeBestsItemText">
                  <h4>ДОСТАВКА ПО РК</h4>
                  <span>Доставим товар в регионы</span>
              </div>
            </div>
        </div>
    );
}

export default HomeBests;
import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Category(){
    const [section, setSection] = useState([]); // API object of sections and categories
    const [categoryInput, setCategory] = useState({
        name: '',
        slug: '',
        category_code: '',
        section: '',
        description: '',
        status: '',
        meta_title: '',
        meta_keywords: '',
        meta_description: '',
        error_list: [],
    }); //Get all input data


    useEffect(() => {//Get all sections from db
        window.scrollTo(0, 0);
        axios.get(`/api/getSections`).then(res => {
            if (res.data.status === 200) {
                setSection(res.data.section);
            }
        });
    }, []);

    //Get sections
    let sectionList = '';
    sectionList = section.map((item, idx) => {
        return <option key={idx} value={item.id}>{item.name}</option>
    });

    

    const handleInput = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setCategory({ ...categoryInput, [e.target.name]: e.target.value });
    }



    const submitCategory = (e) => {
        e.preventDefault();

        const data = {
            name:  categoryInput.name,
            slug:  categoryInput.slug,
            category_code:  categoryInput.category_code,
            section:  categoryInput.section, 
            description:  categoryInput.description,
            //status:  categoryInput.status,           
            meta_title:  categoryInput.meta_title,
            meta_keywords:  categoryInput.meta_keywords,
            meta_description:  categoryInput.meta_description,
        }

        axios.post(`/api/store-category`, data).then(res => {
            if(res.data.status === 200){
                swal("Категория успешно создана!", res.data.message, 'success');
                document.getElementById('CATEGORY_FORM').reset();
            } else if(res.data.status === 400){
                toast.error(res.data.message);
                setCategory({ ...categoryInput, error_list:res.data.errors }); //Записываем ошибки в массив
            }
        });
    }


    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Добавить категорию</h1>
            <form onSubmit={submitCategory} id="CATEGORY_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">Теги для СЕО</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Название категории <span className="red">*</span></b></label>
                            <input type="text" required name="name" onChange={handleInput} value={categoryInput.name} className="form-control" />
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Транслитерация <span className="red">*</span></b></label>
                            <input type="text" required name="slug" onChange={handleInput} value={categoryInput.slug} className="form-control" />
                        </div>      

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Код категории <span className="red">*</span></b></label>
                            <input type="text" required name="category_code" onChange={handleInput} value={categoryInput.category_code} className="form-control" />
                        </div>   

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Секция для категории <span className="red">*</span></b></label><br/>
                            <select required className="form-select col-md-2" onChange={handleInput} value={categoryInput.section} name="section">
                                <option>Выберите секцию</option>
                                {sectionList}
                            </select>
                        </div>                 

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание категории</b></label>
                            <textarea type="text" name="description" onChange={handleInput} value={categoryInput.description} className="form-control" ></textarea>
                        </div>
                        
                        {/* <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleInput} value={categoryInput.status} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включена / есть галочка = отключена</p>
                        </div> */}
                        
                    </div>
                    <div className="tab-pane fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                        <div className="form-group mb-3 mt-5">
                            <label className="mb-2"><b>Заголовок для SEO</b></label>
                            <input type="text" name="meta_title" onChange={handleInput} value={categoryInput.meta_title} className="form-control" />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ключевые слова SEO</b></label>
                            <textarea type="text" name="meta_keywords" onChange={handleInput} value={categoryInput.meta_keywords} className="form-control" ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание SEO</b></label>
                            <textarea type="text" name="meta_description" onChange={handleInput} value={categoryInput.meta_description} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Сохранить</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default Category;
import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';


function AdminPassword() {
    const [loading, setLoading] = useState(false);
    const [passwordInput, setPassword] = useState([]);

    const handleInput = (e) => {
        e.persist();
        setPassword({ ...passwordInput, [e.target.name]: e.target.value });
    }

    const updatePassword = (e) => {
        e.preventDefault();

        const data = {
            new_password: passwordInput.new_password,
            new_confirm_password: passwordInput.new_confirm_password,
        }

        
        axios.post(`/api/changePassword`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Пароль изменен', res.data.message, 'success');                
            } else if (res.data.status === 420) {
                setPassword([]);
            }
        });
    }

    const tigerUpload = (e) =>{
        setLoading(true);
        e.preventDefault();

        axios.post(`/api/tigerUpload`).then(res=>{
            if(res.data.status === 200){
                swal ('База успешно обновилась', res.data.message, 'success');
                setLoading(false);
            } else if (res.data.status === 420) {
                swal ('Произошла ошибка обновления', res.data.message, 'error');
                setLoading(false);
            }
        });
    }

  return (
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card mt-4">                    
                    <div className="card-header">
                        <h4>Смена пароля администратора</h4>
                    </div>
                    <div class="card-body">
                        <form onSubmit={updatePassword}>      
    
                            <div class="form-group row">
                                <label for="password" className="col-md-4 col-form-label text-md-right">Новый пароль</label>
    
                                <div class="col-md-6">
                                    <input 
                                        id="new_password" 
                                        onChange={handleInput} 
                                        value={passwordInput.new_password} 
                                        type="password" 
                                        class="form-control" 
                                        required
                                        name="new_password"
                                    />
                                </div>
                            </div>
    
                            <div class="form-group row mt-2">
                                <label for="password" className="col-md-4 col-form-label text-md-right">Повторите пароль</label>
        
                                <div class="col-md-6">
                                    <input 
                                        id="new_confirm_password"
                                        onChange={handleInput}
                                        value={passwordInput.new_confirm_password}
                                        type="password"
                                        className="form-control"
                                        required
                                        name="new_confirm_password"
                                    />
                                </div>
                            </div>
    
                            <div class="form-group row mb-0 mt-2">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" className="btn btn-primary">
                                        Обновить пароль
                                    </button>
                                </div>
                            </div>

                        </form>

                        <form onSubmit={tigerUpload}>   
    
                            <div class="form-group row mb-0 mt-2">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" className="btn btn-success" disabled={loading}>
                                        {loading && <i className="fas fa-refresh fa-spin" aria-hidden="true"></i> }
                                        {loading && 'Загрузка товаров в базу...' }
                                        {!loading && 'Выгрузиться с Tiger' }                                        
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminPassword

import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

function EditBrand(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [brandInput, setBrand] = useState([]); //Get all input data
    const [allCheckbox, setAllCheckbox] = useState('');
    
    useEffect(() => {  
        const brand_id = props.match.params.id;

        axios.get(`/api/edit-brand/${brand_id}`).then(res => {
            if(res.data.status === 200){
                setBrand(res.data.brand);
                setAllCheckbox(res.data.brand);
                console.log(allCheckbox);
            } else if (res.data.status === 404) {
                history.push('/admin/view-brand');
            }
            setLoading(false);
        });
    }, [props.match.params.id, history]);

    

    const handleCheckbox = (e) => {
        e.persist();
        setAllCheckbox({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const handleInput = (e) => {
        e.persist();
        setBrand({ ...brandInput, [e.target.name]: e.target.value });
    }
    
    const updateBrand = (e) => {
        e.preventDefault();

        const brand_id = props.match.params.id;
        const data = {
            name: brandInput.name,
            image: brandInput.image,
            status: allCheckbox.status ? '1' : '0',
        };

        axios.put(`/api/update-brand/${brand_id}`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Секция успешно обновлена', res.data.message, 'success');
            }
        });
    }

    if(loading){
        return <h4>Загрузка бренда...</h4>
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Редактировать бренд - {brandInput.name}</h1>
            <form  onSubmit={updateBrand} id="BRAND_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Название бренда<span className="red">*</span></b></label>
                            <input type="text" required name="name" onChange={handleInput} value={brandInput.name} className="form-control" />
                            <p className="red">Бренд должен называться в точности так же, как у товара!</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Картинка бренда<span className="red">*</span></b></label>
                            <input type="text" required name="image" onChange={handleInput} value={brandInput.image} className="form-control" />
                        </div>

                        <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleCheckbox} defaultChecked={allCheckbox.status === '1' ? true : false} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включен / есть галочка = отключен</p>
                        </div> 

                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
            </form>
        </div>
    )

}

export default EditBrand

import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

function EditSection(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [sectionInput, setSection] = useState([]);
    
    useEffect(() => {  
        const section_id = props.match.params.id;

        axios.get(`/api/edit-section/${section_id}`).then(res => {
            if(res.data.status === 200){
                setSection(res.data.section);
            } else if (res.data.status === 404) {
                history.push('/admin/view-section');
            }
            setLoading(false);
        });
    }, [props.match.params.id, history]);

    

    const handleInput = (e) => {
        e.persist();
        setSection({ ...sectionInput, [e.target.name]: e.target.value });
    }

    const updateSection = (e) => {
        e.preventDefault();

        const section_id = props.match.params.id;
        const data = sectionInput;
        
        axios.put(`/api/update-section/${section_id}`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Секция успешно обновлена', res.data.message, 'success');
            }
        });
    }

    if(loading){
        return <h4>Загрузка секции...</h4>
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Редактировать секцию - {sectionInput.name}   </h1>
            <form  onSubmit={updateSection} id="SECTION_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Название секции <span className="red">*</span></b></label>
                            <input type="text" required name="name" onChange={handleInput} value={sectionInput.name} className="form-control" />
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
            </form>
        </div>
    )

}

export default EditSection

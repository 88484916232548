import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

function EditSlide(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [slideInput, setSlide] = useState([]);
    const [statusCheckbox, setStatusCheckbox] = useState('');


    useEffect(() => {  
        const slide_id = props.match.params.id;

        axios.get(`/api/edit-slide/${slide_id}`).then(res => {
            if(res.data.status === 200){
                setSlide(res.data.slide);
                setStatusCheckbox(res.data.slide);
            }
            setLoading(false);
        });
    }, [props.match.params.id, history]);

    const handleCheckbox = (e) => {
        e.persist();
        setStatusCheckbox({ ...statusCheckbox, [e.target.name]: e.target.checked });
    }

    const handleInput = (e) => {
        e.persist();
        setSlide({ ...slideInput, [e.target.name]: e.target.value });
    }

    const updateCategory = (e) => {
        e.preventDefault();

        const slide_id = props.match.params.id;
        const data = {
            image: slideInput.image,
            link: slideInput.link,
            status: statusCheckbox.status ? 1 : 0,
        };

        axios.put(`/api/update-slide/${slide_id}`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Слайд успешно обновлен', res.data.message, 'success');
            }
        });
    }

    if(loading){
        return <h4>Загрузка слайда...</h4>
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Редактировать слайд</h1>
            <form onSubmit={updateCategory} id="SLIDE_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Путь картинке<span className="red">*</span></b></label>
                            <input type="text" required name="image" onChange={handleInput} value={slideInput.image} className="form-control" />
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ссылка</b></label>
                            <input type="text" name="link" onChange={handleInput} value={slideInput.link} className="form-control" />
                        </div>
                        
                        <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleCheckbox} defaultChecked={statusCheckbox.status === 1 ? true : false} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включен / есть галочка = отключен</p>
                        </div>
                        
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Обновить слайд</button>
            </form>
        </div>
    )
}

export default EditSlide

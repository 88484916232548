import React, { useEffect, useState } from "react";
import Header from './Header';
import Footer from './Footer';
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from "../../features/cartSlice";
import { addToFav } from "../../features/favSlice";
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import Modal from "./Modal/Modal";
import CategoryImage from "../../frontend/pages/CategoryImage";
import {Helmet} from "react-helmet";




function ProductDetails(props){

    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {  
        const newItem = {...item, quantity: qty};                
        dispatch(addToCart(newItem))        
    } 
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }
    const history = useHistory();
    const [product, setProduct] = useState([]);
    const [related, setRelated] = useState([]);
    const [category, setCategory] = useState([]);
    const [qty, setQuantity] = useState(1);
    const favourite = useSelector ((state) => state.fav.favItems);
    const isItemInFavourite = favourite.some(item => item.id === product.id); 
    
    useEffect(() => {
        window.scrollTo({top: 1, left: 0, behavior: 'smooth' });
        let isMounted = true;
        const product_slug = props.match.params.product;
        const category_slug = props.match.params.category;
        //console.log(category_slug)
        
        axios.get(`/api/view-product/${category_slug}/${product_slug}`).then(res => {
            if(isMounted){
                if(res.data.status === 200){
                    setProduct(res.data.product);
                    setCategory(res.data.category);
                    setRelated(res.data.related);
                    document.title = res.data.product.name;
                }  else if( res.data.status === 404 ){
                    history.push('/catalog');
                    toast.error('Нет такой категории');
                }
            }             
        });
        return () => {
            isMounted = false;
        }        
       
    }, [props.match.params.category, props.match.params.product, history]);

    const handleDecrement = () =>{
        if(qty > 1){
            setQuantity((prevCount) => prevCount - 1); //Надо отследить сколько товара на складе
        }
    }

    const handleIncrement = () =>{
        setQuantity(Number(qty + 1)); //Надо отследить сколько товара на складе
    }

    const handleQty = (e, qty) => {
        if(e.target.value > qty){
            e.target.value = qty;
            setQuantity(parseInt(qty));
            toast.error(`На складе всего ${qty} шт.`, { position:"bottom-left" });
        }else{
            setQuantity(parseInt(e.target.value));
        }
     };
 
    var showProductList = '';

    showProductList = related.map((item, idx) => { //Maping the result of filtering
        const isItemInFavourite = favourite.some(items => items.id === item.id);
        return(
            <div className="homeProductItem" key={idx}>
                <div className="productFront">
                    <div className="homeProductItemImg">
                        <Link to={`/catalog/${category.slug}/${item.slug}`}>
                            <CategoryImage image={item.image} title={item.name} qty={item.qty}/>
                        </Link>
                        {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                    </div>
                    <Link to={`/catalog/${category.slug}/${item.slug}`}><h2 className="productH2">{item.name}</h2></Link>
                    <div className="homeProductItemInfo">
                        <div className="homeProductItemCode">Код: {item.SKU}</div>
                        <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                        {item.selling_price 
                        ?
                        <div>
                            <div className="homeProductItemPrice red"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.selling_price)} /> <span><strong>₸</strong></span></div>
                            <div className="homeProductItemPriceSale"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                        </div>
                        :
                        <div className="homeProductItemPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                        }
                    </div>
                    <div className="catProductBtn">
                        {item.qty > 0 ? <button
                            className="catProdAdd cartBtnProduct tooltip2"
                            onClick={() => handleAddToCart(item, 1)}
                            data-tooltip="Корзина"
                        ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button> : <button
                            className="catProdAddRed cartBtnProduct notRegistered tooltip3"
                            data-tooltip="Под заказ"
                            disabled
                        ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button>}


                        <button
                            onClick={() => handleAddToFav(item)}
                            className="catProdAdd favBtnProduct tooltip3"
                            data-tooltip={isItemInFavourite ? "Убрать из избранного" : "Избранное"}
                        ><i className="fa-solid fa-heart"></i></button>
                        <button
                            className="catProdAdd compBtnProduct tooltip2"   
                            data-tooltip="Сравнить"                         
                        ><i className="fas fa-balance-scale" aria-hidden="true"></i></button>
                    </div>
                    <div className="productBackground"></div>
                </div>
            </div>
        )
    });
    
    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>{`${product.name}`} | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content={`${product.name}, Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция`} />
                <meta name="description" content={`${product.name},✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${product.name} | Horecamarket Market`} />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content={`https://horecamarket.kz/${category.slug}/${product.slug}`} />
                <meta property="og:locale" content="ru_RU" />
            </Helmet>
            <main>
                <Header />   
                <div className="container"> 
                <div className="commonPagesContentWrap">
                    <h1>{product.name}</h1>
                    <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>
                                    <Link to={`/catalog/${category.slug}`}>  
                                        {category.name}
                                    </Link>
                                </li> 
                                /
                                <li>{product.name}</li>                                
                            </ul>
                        </div>
                    <div className="productDetails">
                        <a href={`/uploads/products/${product.image}`} data-fancybox="group">
                            <img src={`/uploads/products/${product.image}`} className="cartImageMain" alt={product.name} /> 
                        </a>
                        
                        <div className="productDetailsContent">
                            <div className="productDetailsSku"><strong>Артикул: </strong>{product.SKU}</div>
                            <div className="productDetailsBrand"><strong>Бренд: </strong>{product.brand}</div>
                            <div className="productDetailsStock">
                                {product.qty > 0 ? <div className="productDetailsStockYes">В наличии</div> : <div className="productDetailsStockNo">Под заказ</div>}
                            </div>
                            <div className="productDetailsRemark">
                                {product.remark ? <div className="productImgRemark">{product.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                            </div>
                            <div className="productDetailsDescr"> {product.description}</div>
                            {product.selling_price 
                            ?
                            <div>
                                <div className="homeProductItemPrice red">Цена: <NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(product.selling_price)} /> <span><strong>₸</strong></span></div>
                                <div className="productDetailsSPriceSale">Старая цена: <strong><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(product.price)} /> <span>₸</span></strong></div>
                            </div>
                            :
                            <div className="productDetailsSPrice">Цена: <strong><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(product.price)} /> <span>₸</span></strong></div>
                            }   
                            
                            <div className="productQty">Количество:</div>
                            <div className="quantity-input">
                                <div className="cartProdQty">
                                    <button className="cartMinus" onClick={handleDecrement}>-</button>
                                    <input 
                                        value={qty > product.qty ? product.qty : qty }
                                        className="cartCountInp" min={1} 
                                        type={'number'} 
                                        onChange={(e) => handleQty(e, product.qty)} 
                                    />
                                    <button className="cartPlus" onClick={handleIncrement}>+</button>
                                </div>
                            </div>
                            <div className="productCartBtns">
                                {product.qty <= 0 ?
                                <button 
                                    className="productCartBtn"
                                    onClick={() => setOpenModal(true)}
                                >Под заказ</button> 
                                : 
                                <button className="productCartBtn" onClick={() => handleAddToCart(product, qty)} ><i className="fas fa-shopping-bag" aria-hidden="true"></i>В корзину</button>                                
                                }
                                {openModal && <Modal closeModal={setOpenModal} productName={product.name} />}
                                <button className="productFavBtn" onClick={() => handleAddToFav(product)}><i className="fas fa-heart" aria-hidden="true"></i>{isItemInFavourite ? 'Убрать из избранного' : 'В избранное'}</button>
                            </div>
                        </div>
                    </div>                    
                </div>
                    <div className="relatedWrap">
                        <h2>Рекомендуемые товары</h2>
                        <div className="related">
                            {showProductList}
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>                
            </main>
            <Footer />
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    );
}

export default ProductDetails;

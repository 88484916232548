import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


function About() {
  const [loading, setLoading] = useState(true);
  const [aboutInput, setAbout] = useState('');


  useEffect(() => {
    axios.get(`/api/view-settings`).then(res => {
      if (res.data.status === 200) {
        setAbout(res.data.setting.about);
      }
      setLoading(false);
    }).catch();
  }, []);

  const handleInput = (e, editor) => {
    const eData = editor.getData();
    setAbout(eData);
  }


  const updateAbout = (e) => {
    e.preventDefault();
    console.log(aboutInput)
    const data = aboutInput;

    axios.put(`/api/update-about`, {data}).then(res => {
      if (res.data.status === 200) {
        swal('Страница успешно обновлена', res.data.message, 'success');
      }
    });
  }


  if (loading) {
    return <h4>Загрузка контента...</h4>
  } else {
    return (
      <div className="container-fluid px-4">
        <h1 className="mt-4 mb-5">Раздел о компании</h1>
        <form onSubmit={updateAbout} id="BRAND_FORM">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

              <div className="form-group mb-3">
                <CKEditor
                  editor={ClassicEditor}
                  onChange={handleInput}
                  data={aboutInput}
                />                
              </div>

            </div>
          </div>
          <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
        </form>
      </div>
    )
  }
}

export default About

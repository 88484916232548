import React from 'react'

export default function Skeleton({ type }) {

  const COUNTER = 8;

  const FeedSkeleton = () => (
    <div className='postSkWrap'>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
      <div className='postSk'>
        <div className='postImgSk'></div>
        <div className='postInfoSk'></div>
        <div className='postPrice'></div>
      </div>
    </div>
  );
  const ImgDetailsProduct = () => {
    <div className='productDetailsImgSk'>

    </div>
  }
  if (type === "img") return (<ImgDetailsProduct />);
  if (type === "feed") return (<FeedSkeleton />);
}

import React from 'react'

function CategoryImage({image, title, qty}) {
  return (
    <div>
      <img className="productImage" src={`/uploads/productsthumb/${image}`} alt={title} />
      {qty === 0 ? <div className="instockSheeld notInStock">Под заказ</div> : <div className="instockSheeld inStock">В наличии</div>}
    </div>
  )
}

export default CategoryImage

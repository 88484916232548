import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import swal from 'sweetalert';
import { ListGroup, Row, Col, Image, Button } from "react-bootstrap";


const OneOrder = (props) => {
    const [order, setOrder] = useState([]);
    const [orderStatus, setOrderStatus] = useState('');

    useEffect(() => {

        const orderId = props.match.params.id;
        
        axios.get(`/api/order/${orderId}`).then(res=>{
            if(res.data.status === 200){
                setOrder(res.data.orderItems);
                setOrderStatus(res.data.orderStatus.status);
            } else if(res.data.status === 404){

            }
        });

    }, []);


    const handleSelect = (e) => {        
        e.persist();
        setOrderStatus(e.target.value);
    }
    

    const updateOrder = (e) => {
        e.preventDefault();
        const order_id = props.match.params.id;
        const data = {
            status: orderStatus,
        };

        axios.put(`/api/update-order/${order_id}`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Заказ успешно обновлен', res.data.message, 'success');
            }
        });
    }



    var orderId = props.match.params.id;
    var $order = '';

    $order = order.map((item, idx) => {
        return(               
                <Row key={idx}>
                    <Col md={2}>
                        <Image src={`/uploads/productsthumb/${item.image}`} alt={item.name} fluid rounded />
                    </Col>
                    <Col md={3}>
                        <h3 className='adminProductName'>{item.name}</h3>
                    </Col>
                    <Col md={1}>
                        <h3>{item.qty}</h3>
                    </Col>
                    <Col md={2}>
                        <h3>{item.SKU}</h3>
                    </Col>
                    <Col md={2}>
                        <div className="cartProdPrice">{item.price} ₸</div>
                    </Col>
                    <Col md={2}>
                        <div className="cartProdTotalPrice">{item.price * item.qty} ₸</div>
                    </Col>
                </Row>
        )
    });

  return (
    <div className="root">
            <main>
                <div className="container">
                    <form onSubmit={updateOrder} className="commonPagesContentWrap">
                        <h1>Содержимое заказа № {orderId}</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/admin/orders">Все заказы</Link></li>
                                /
                                <li>Заказ № {orderId}</li>
                            </ul>
                            
                            <select className="form-select mt-4" onChange={handleSelect} >
                                {orderStatus === 'ordered' ? <option selected value="ordered">Заказ оформлен</option> : <option value="ordered">Заказ оформлен</option>}
                                {orderStatus === 'delivery' ? <option selected value="delivery">Доставка</option> : <option value="delivery">Доставка</option>}
                                {orderStatus === 'paid' ? <option selected value="paid">Оплачен</option> : <option value="paid">Оплачен</option>}
                                {orderStatus === 'delivered' ? <option selected value="delivered">Доставлен</option> : <option value="delivered">Доставлен</option>}
                                {orderStatus === 'canceled' ? <option selected value="canceled">Отменен</option> : <option value="canceled">Отменен</option>}
                            </select>
                        </div>
                        <div className="orderContent">
                            <ListGroup.Item  > 
                                <Row className='adminOrderHeader'>
                                    <Col md={2}>
                                        Картинка
                                    </Col>
                                    <Col md={3}>
                                        Название
                                    </Col>
                                    <Col md={1}>
                                        Кол-во
                                    </Col>
                                    <Col md={2}>
                                        Код товара
                                    </Col>
                                    <Col md={2}>
                                        <div className="cartProdPrice">Цена</div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="cartProdTotalPrice">Итого</div>
                                    </Col>
                                </Row>
                            {$order}                                        
                            </ListGroup.Item>
                        </div>
                        <button type="submit" className="btn btn-primary px-4 float-end mt-2">Обновить</button>
                    </form>
                  </div>
                <div className="hFooter"></div>
            </main>
        </div>
  )
}

export default OneOrder

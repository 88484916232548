import React, { useEffect, useState } from "react";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";


function HomeBrands(){
    const [brands, setBrands] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() =>{
        axios.get(`/api/getBrands`).then(res => {
            if(res.data.status === 200){
                setBrands(res.data.allBrands);
            }   
        });
    }, []);

    let viewBrand = '';

    viewBrand = brands.map((item, idx) => {
            return(
                <Link key={idx} to={`brand/${item.slug}`}>
                    <img src={`/uploads/brands/${item.image}`} key={idx} />
                </Link>
            )
        });

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplaySpeed: 2000,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: "unslick",
            }
        ]
    };
    return (
        <div className="homeBrands">
            <div className="homeBrandsWrap">
                <Slider {...settings}>
                    {width < 500 ? viewBrand.slice(0, 4) : viewBrand}
                </Slider>
            </div>
            <div className="homeBrandsBtn">
                <Link to={'/brands'}>Показать все бренды</Link>
            </div>
        </div>
    );
}

export default HomeBrands;
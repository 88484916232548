import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

function EditCategory(props) {
    const [section, setSection] = useState([]);
    const [sectionId, setSectionId] = useState([]);
    const [sectionName, setSectionName] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [categoryInput, setCategory] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [statusCheckbox, setStatusCheckbox] = useState('');


    useEffect(() => {//Get all sections from db
        window.scrollTo(0, 0);
        axios.get(`/api/getSections`).then(res => {
            if (res.data.status === 200) {               
                setSection(res.data.section);
            }
        });
    }, []);

    //Get sections
    let sectionList = '';
    sectionList = section.map((item, idx) => {
        return <option key={idx} value={item.id}>{item.name}</option>
    });
    useEffect(() => {  
        const category_id = props.match.params.id;

        axios.get(`/api/edit-category/${category_id}`).then(res => {
            if(res.data.status === 200){
                setCategory(res.data.category);
                setCategoryName(res.data.category.name);
                setSectionId(res.data.section_id);
                setSectionName(res.data.section);
                setStatusCheckbox(res.data.category);
            } else if (res.data.status === 404) {
                toast.error('Не удалось найти категорию');
                history.push('/admin/view-category');
            }
            setLoading(false);
        });
    }, [props.match.params.id, history]);

    

    const handleInput = (e) => {
        e.persist();
        setCategory({ ...categoryInput, [e.target.name]: e.target.value });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setStatusCheckbox({ ...statusCheckbox, [e.target.name]: e.target.checked });
    }

    const updateCategory = (e) => {
        e.preventDefault();

        const category_id = props.match.params.id;
        const data = {
            name: categoryInput.name,
            slug: categoryInput.slug,
            category_code: categoryInput.category_code,
            section: categoryInput.section,
            description: categoryInput.description,
            meta_title: categoryInput.meta_title,
            meta_keywords: categoryInput.meta_keywords,
            meta_description: categoryInput.meta_description,
            status: statusCheckbox.status ? 1 : 0,
        };
        axios.put(`/api/update-category/${category_id}`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Категория успешно обновлена', res.data.message, 'success');
            }
        });
    }

    if(loading){
        return <h4>Загрузка категории...</h4>
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Редактировать категорию - {categoryName}</h1>
            <form onSubmit={updateCategory} id="CATEGORY_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">Теги для СЕО</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Название категории <span className="red">*</span></b></label>
                            <input type="text" required name="name" onChange={handleInput} value={categoryInput.name} className="form-control" />
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Транслитерация <span className="red">*</span></b></label>
                            <input type="text" required name="slug" onChange={handleInput} value={categoryInput.slug} className="form-control" />
                        </div>      

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Код категории <span className="red">*</span></b></label>
                            <input type="text" required name="category_code" onChange={handleInput} value={categoryInput.category_code} className="form-control" />
                        </div>   

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Секция для категории <span className="red">*</span></b></label><br/>
                            <select required className="form-select col-md-2" onChange={handleInput} value={categoryInput.section} name="section">
                                <option value={sectionId}>{sectionName}</option>
                                {sectionList}
                            </select>
                        </div>                 

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание категории</b></label>
                            <textarea type="text" name="description" onChange={handleInput} value={categoryInput.description ? categoryInput.description : ''} className="form-control" ></textarea>
                        </div>
                        
                        <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleCheckbox} defaultChecked={statusCheckbox.status === 1 ? true : false} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включена / есть галочка = отключена</p>
                        </div>
                        
                    </div>
                    <div className="tab-pane fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                        <div className="form-group mb-3 mt-5">
                            <label className="mb-2"><b>Заголовок для SEO</b></label>
                            <input type="text" name="meta_title" onChange={handleInput} value={categoryInput.meta_title ? categoryInput.meta_title : ''} className="form-control" />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ключевые слова SEO</b></label>
                            <textarea type="text" name="meta_keywords" onChange={handleInput} value={categoryInput.meta_keywords ? categoryInput.meta_keywords : ''} className="form-control" ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание SEO</b></label>
                            <textarea type="text" name="meta_description" onChange={handleInput} value={categoryInput.meta_description ? categoryInput.meta_description : ''} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )

}

export default EditCategory;
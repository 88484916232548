import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";



function Contacts() {
    const [settings, setSettings] = useState([]);
    const [phoneNumber, setPhone] = useState('');
    const [whatsApp, setWa] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`/api/view-settings`).then(res => {
            if (res.data.status === 200) {
                setSettings(res.data.setting);
                setPhone(res.data.setting.phone.split(" ").join(""));
                setWa(res.data.setting.whatsapp.split(" ").join(""));
            }
        }).catch();
    }, []);

    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Контакты. Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content="https://horecamarket.kz/" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Контакты</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Контакты</li>
                            </ul>
                        </div>
                        <div className='commonPagesContent contactsContentWrapper' >
                            <div className="contactsLeft">
                                <div className="contactsContent">
                                    <div className="footerInfoA">
                                        <i className="fa-solid fa-phone-volume"></i>
                                        <a href={`tel:${phoneNumber}`}>{settings.phone}</a>
                                    </div>
                                    <div className="footerInfoA">
                                        <i className="fa-brands fa-whatsapp"></i>
                                        <Link to={{ pathname: `https://wa.me/${whatsApp}` }} target="_blank" rel="noreferrer" >{settings.whatsapp}</Link>
                                    </div>
                                    <div className="footerInfoA">
                                        <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                        <Link to={{ pathname: settings.map_link }} rel="noreferrer" target="_blank">{settings.address}</Link>
                                    </div>
                                    <div className="footerInfoA">
                                        <i className="fa-solid fa-envelope"></i>
                                        <a href={`mailto:${settings.email}`} >{settings.email}</a>
                                    </div>
                                    <div className="footerSocial">
                                        <Link to={{ pathname: `${settings.facebook}` }} target="_blank" rel="noreferrer">
                                            <i className="fab fa-facebook"></i>
                                        </Link>
                                        <Link to={{ pathname: `${settings.instagram}` }} target="_blank" rel="noreferrer">
                                            <i className="fab fa-instagram"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="contactsRight" dangerouslySetInnerHTML={{__html: settings.map_link}}></div>
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Contacts;
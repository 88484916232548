import React, { useState, useEffect } from "react";
import LogoTop from '../../images/headerLogo.png';
import SearchImg from '../../images/searchIcon.png';
import { useHistory, Link  } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart } from "../../features/cartSlice";
import HeaderMenuItem from "./HeaderMenuItem";
import NumberFormat from 'react-number-format';
import CallBack from "./CallBack/Modal";

function Header() {
    
    const [scrollFix, setScrollFix] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const cart = useSelector ((state) => state.cart); // Getting cart object from state
    const favourite = useSelector ((state) => state.fav.favItems); // Getting favourite object from state
    const history = useHistory();// Redirect
    const [section, setSection] = useState([]); // API object of sections and categories
    const [searchInput, setSearchInput] = useState('none');
    const [searchWord, setSearchWord] = useState('');
    const [searchFilteringData, setSearchFilteringData] = useState([]);

    const [settings, setSettings] = useState([]);
    const [phoneNumber, setPhone] = useState('');

    useEffect(() => {
        axios.get(`/api/view-settings`).then(res => {
            if (res.data.status === 200) {
                setSettings(res.data.setting);
                setPhone(res.data.setting.phone.split(" ").join(""));
            }
        }).catch();
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    useEffect(() => {
        axios.get(`/api/getSections`).then(res => {
            if (res.data.status === 200) {
                setSection(res.data.section);
            }
        });
    }, []);

    const dispatch = useDispatch();
    const handleRemoveFromCart = (cartItem) => {
        dispatch(removeFromCart(cartItem)); // Changing state of cart items - deleting item row by id
    }

    //Category menu
    let sectionListMenu = '';
    sectionListMenu = section.map((item, idx) => {
        return (
            <div className="headerNavItem" key={idx}>
                <div className="headerSectionItem">
                    <img className="homeMenuImg" src={`/uploads/categories/${item.image}`} alt="" />
                    <p>{item.name}</p>
                </div>              
                <HeaderMenuItem categories={item.categories} />
            </div>
        )
    });


    //Search
    const searchHandler = async (event) => {
        const searchWord = event.target.value;
        setSearchWord(searchWord);
        if (searchWord.length > 3) {

            const { data } = await axios.get(`/api/searchresult/${searchWord}`);       
            if(data.products){   
                setSearchFilteringData(data.products.slice(0,30));
                setSearchInput('block');
            }
        } else {
            setSearchFilteringData([]);
            setSearchInput('none');
        }
    }
    
    let searchList = '';
    searchList = searchFilteringData.slice(0, 10).map((item, idx) => {
        return (
            <div key={idx}>
                { item.searchCategory === null ? ' ' :
                    <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`} className="headerSearchItem">
                        <img className="homeMenuImg" src={`/uploads/productsthumb/${item.image}`} height={30} width={30} alt={item.name} />
                        <p>{item.name}</p>
                    </Link>
                }
            </div>
        )
    });// end search


    const logoutSubmit = (e) => {
        e.preventDefault();

        axios.post(`/api/logout`).then(res => {
            console.log(res);
            if (res.data.status === 200) {
                localStorage.removeItem('auth_token', res.data.token);
                localStorage.removeItem('auth_name', res.data.username);
                localStorage.removeItem('role', res.data.role);

                history.push('/');
                swal("Horeca Market", res.data.message, 'success');
            } 
        }).catch(function (error) {
          if(error.response.status==401){
                localStorage.clear();
                history.push('/login');
          }
        });
    }
    const handleSearchBtn = () => {
        history.push(`/searchresult/${searchWord}`);
    }

    let buttons = '';
    if (localStorage.getItem('auth_token')) {
        buttons = (
            <div>
                <h4>Здравствуйте, { localStorage.getItem('auth_name') }.</h4>
                <ul>
                    <li><Link to="/logout" onClick={logoutSubmit}>Выход</Link></li> / <li>{localStorage.getItem('role') === 'admin' ? <Link to="/admin/dashboard">Кабинет</Link> : <Link to="/dashboard">Кабинет</Link>}</li>
                </ul>
            </div>
        )
    } else {
        buttons = (
            <ul>
                <li><Link to="/login">Вход</Link></li> / <li><Link to="/register">Регистрация</Link></li>
            </ul>
        )
    }//end Logout
    if(width <= 768){

    }else{
        const changeValueOnScroll = () => {   
            const scrollValue = document.documentElement.scrollTop;
            if (scrollValue > 390) {
                setScrollFix(true);
            } else {
                setScrollFix(false);
            }
        }//End scroll event
        window.addEventListener ('scroll', changeValueOnScroll);
    }
    return (
        <header>
            <div className="headerWrapper">
                <div className="container">
                    <div className="headerTopWrap">
                        <div className="headerTopLeft">
                            <ul>
                                <li><Link to="/about">О компании</Link></li>
                                <li><Link to="/delivery">Доставка и оплата</Link></li>
                                <li><Link to="/brands">Бренды</Link></li>
                                <li className='headerNavSaleRed'><Link to="/sale">Акции</Link></li>
                                <li><Link to="/contacts">Контакты</Link></li>
                            </ul>
                        </div>
                        <div className="headerTopInfo">
                            <a href={`tel:${phoneNumber}`}>
                                <i className="fas fa-phone-volume" aria-hidden="true"></i>{settings.phone}
                            </a>
                            <div className="headerTopInfoAdress">
                                <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                <Link to={{pathname:settings.map_link}} rel="noreferrer" target="_blank">{settings.address}</Link>
                            </div>
                            <a href={`mailto:${settings.email}`}>
                                <i className="fas fa-envelope" aria-hidden="true"></i>
                                {settings.email}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="headerBottomWrap" id={scrollFix ? 'headerMenuFixed' : ''}>
                <div className="container">
                    <div className="headerBottom">
                        <div className="headerBottomLogo">
                            <Link to="/"><img src={LogoTop} alt="Horeca Market Интернет магазин" /></Link>
                            <p>Легкие решения сложных задач</p>
                        </div>
                        <div className="headerSearchTextWrap">
                            <p>{settings.site_name}</p>
                            <div className='headerForm'>
                                <form >
                                    <input 
                                        type="text" 
                                        required
                                        placeholder='Поиск по всему каталогу...'
                                        onChange={searchHandler}
                                        onBlur={() =>
                                            setTimeout(() => {
                                                setSearchInput('none');
                                            }, 100)
                                        }
                                    />
                                    <button type="submit" onClick={searchWord === '' ? null : handleSearchBtn}><span>Поиск</span> <img src={SearchImg} alt="Logo" /></button>
                                    <div className="headerSearchResult" style={{display: searchInput}} >
                                        {searchList}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="headerLoginCartFavourite">                            
                            <div className="loginWrap">
                                <i className="fas fa-user-tie" aria-hidden="true"></i>{buttons}
                            </div>
                            <div>
                                <div className="compareFavouriteCart">
                                    <div className="headerComWrap">
                                        <Link to="/compare">
                                            <div className="headerCart">
                                                <i className="fas fa-balance-scale"></i>
                                                <p>0</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="headerFavWrap">
                                        <Link to="/favourite">
                                            <div className="headerCart">
                                                <i className="fa-solid fa-heart"></i>
                                                <p>{favourite.length}</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="headerCartWrap">
                                        <Link to="/cart">
                                            <div className="headerCart">
                                                <i className="fas fa-shopping-cart"></i>
                                                <p>{cart.cartItems.length}</p>
                                            </div>
                                        </Link>
                                        <div id="dropdown">
                                            {cart.cartItems.length > 0 ? (     // Cart block                                         
                                            cart.cartItems.map((prod, i) => {
                                                return (
                                                    <div className="headerCartItem" key={i}>
                                                        <img src={`/uploads/productsthumb/${prod.image}`} alt={prod.name} />
                                                        <div className="headerItemContent">
                                                            <h2>{prod.name}</h2>
                                                            {prod.selling_price 
                                                            ?
                                                            <div>
                                                                <p className="red"><strong><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(prod.selling_price)} /> ₸</strong></p>
                                                                <p className="headerTopSalePrice"><strong><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(prod.price)} /> ₸</strong></p>                                                            
                                                            </div>
                                                            :
                                                            <p><strong><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(prod.price)} /> ₸</strong></p>
                                                            }
                                                        </div>
                                                        <div className="headerCartQty">{prod.cartQuantity} шт.</div>
                                                        <button type="button" onClick={() => handleRemoveFromCart(prod)}><i className="fas fa-trash-alt"></i></button>
                                                    </div>
                                                )
                                            })
                                            ) : (
                                                <span>Корзина пуста!</span>
                                            )}
                                            {cart.cartItems.length > 0 ? <div className="headerPurchaseOrder"><Link to="/cart">В корзину</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="headerBtn" onClick={() => setOpenModal(true)}>
                                    <i className="fas fa-phone-volume" aria-hidden="true" ></i> 
                                    Заказать звонок
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav>
                <div className="container">
                    <div className="headerNavWrapper">
                        {sectionListMenu}
                    </div>
                </div>
            </nav>
            {openModal && <CallBack closeModal={setOpenModal} />}
            <div className="mobileFooter"></div>
            <div className="mobileHeader"></div>
            <a href="tel:+77079953768" className="mobilePhone"><i className="fa-solid fa-phone-volume"></i></a>
        </header>
    );
}

export default Header;
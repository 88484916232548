import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";


function ViewSliders() {
  const [loading, setLoading] = useState(true);
    const [slidesList, setSlidesList] = useState([]);

    useEffect( () => {
        axios.get(`/api/view-slides`).then(res => {
            if(res.data.status === 200){
              setSlidesList(res.data.sliders);
            }
            setLoading(false);
        }).catch();
    }, []);
    let viewslides_HTMLTABLE = '';

    if(loading){
        return <h4>Загрузка слайдов...</h4>
    }else{
        viewslides_HTMLTABLE = 
        slidesList.map((item, idx) => {
            return(
                <tr key={idx}>
                    <td>{item.id}</td>
                    <td>{item.image}</td>
                    <td>{item.link}</td>
                    <td>{item.status == 0 ? 'Включен' : 'Выключен'}</td>
                    <td>
                        <Link to={`/admin/edit-slide/${item.id}`} className="btn btn-success btn-sm">Редактировать</Link>
                    </td>
                </tr>
            )
        });
    }

  return (
    <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                    <h4>Все слайды</h4>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Адрес к картинке</th>
                                <th>Ссылка</th>
                                <th>Статус</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewslides_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  )
}

export default ViewSliders

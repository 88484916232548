import React, {  useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FooterLogo from '../../images/footerLogo.png';
import CallBack from "./CallBack/Modal";


function Footer() {
  const [openModal, setOpenModal] = useState(false);
  const [settings, setSettings] = useState([]);
  const [phoneNumber, setPhone] = useState('');
  const [whatsApp, setWa] = useState('');

  useEffect(() => {
      axios.get(`/api/view-settings`).then(res => {
          if (res.data.status === 200) {
              setSettings(res.data.setting);
              setPhone(res.data.setting.phone.split(" ").join(""));
              setWa(res.data.setting.whatsapp.split(" ").join(""));
          }
      }).catch();
  }, []);
  


  return (
    <footer>
      <div className="container">
        <div className="footerTop">
          <img src={FooterLogo} alt="" />
        </div>
        <div className="footerNavs">
          <div className="footerNav">
            <h4>НАВИГАЦИЯ</h4>
            <ul>
              <li>
                <Link to="/about">О компании </Link>
              </li>
              <li>
                <Link to="/delivery">Доставка и оплата</Link>
              </li>
              <li>
                <Link to="/brands">Бренды</Link>
              </li>
              {/* <li>
                <Link to="/news">Новости</Link>
              </li> */}
              <li>
                <Link to="/sale">Акции</Link>
              </li>
              <li>
                <Link to="/contacts">Контакты</Link>
              </li>
            </ul>
          </div>
          <div className="footerNav">
            <h4>АККАУНТ</h4>
            <ul>
              <li>
                <Link to="/login">Вход</Link>
              </li>
              <li>
                <Link to="/register">Регистрация</Link>
              </li>
              <li>
                <Link to="/favourite">Избранное</Link>
              </li>
              <li>
                <Link to="/cart">Корзина</Link>
              </li>
              <li>
                <Link to="/compare">Сравнение</Link>
              </li>
            </ul>
          </div>
          <div className="footerInfo">
            <h4>ИНФОРМАЦИЯ</h4>
                <div className="footerInfoA">
                  <i className="fa-solid fa-phone-volume"></i>
                  <a href={`tel:${phoneNumber}`}>{settings.phone}</a>
                </div>        
                <div className="footerInfoA">
                  <i className="fa-brands fa-whatsapp"></i>
                  <Link to={{pathname:`https://wa.me/${whatsApp}`}} target="_blank" rel="noreferrer" >{settings.whatsapp}</Link>
                </div>
                <div className="footerInfoA">
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  <Link to={{pathname:settings.map_link}} rel="noreferrer" target="_blank">{settings.address}</Link>
                </div>
                <div className="footerInfoA">
                  <i className="fa-solid fa-envelope"></i>
                  <a href={`mailto:${settings.email}`} >{settings.email}</a>
                </div>
                <div className="footerSocial">
                  <Link to={{pathname:`${settings.facebook}`}} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to={{pathname:`${settings.instagram}`}} target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div>
                <div className="footerBtn">
                  <i className="fa-solid fa-phone-volume"></i>
                  <p onClick={() => setOpenModal(true)}>заказать звонок</p>
                </div>
          </div>
        </div>
        <div className="footerCopy">
            <div className="footerCopyYear">
                2022
            </div>
            <div className="footerCopyright">{settings.copyright}</div>
        </div>
        {openModal && <CallBack closeModal={setOpenModal} />}
      </div>
    </footer>
  );
}

export default Footer;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from "../../../features/cartSlice";
import { addToFav } from "../../../features/favSlice";
import {useDispatch, useSelector} from 'react-redux';
import NumberFormat from 'react-number-format';

function NewsProduct() {
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {
        const newItem = {...item, quantity: qty};
        dispatch(addToCart(newItem))
    } 
    const favourite = useSelector((state) => state.fav.favItems);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }


    const [saleProd, setSaleProd] = useState([]);

    useEffect(() => {
        axios.get(`/api/getRemark/new`).then(res => {
            if (res.data.status === 200) {
                setSaleProd(res.data.products);
            }
        });
    }, []);

    let productList = '';

    productList = saleProd.map((item, idx) => {
        const isItemInFavourite = favourite.some(items => items.id === item.id);
        return (
            <div className="homeProductItem" key={idx}>
                <div className="productFront">
                <div className="homeProductItemImg">
                    <Link to={`catalog/${item.category}/${item.slug}`}>
                        <img src={`/uploads/productsthumb/${item.image}`} className="productImage"  height={230} width={230} alt={item.title} />
                        {item.qty === 0 ? <div className="instockSheeld notInStock">Под заказ</div> : <div className="instockSheeld inStock">В наличии</div>}
                    </Link>
                </div>
                <Link to={`catalog/${item.category}/${item.slug}`}><h2>{item.name}</h2></Link>
                <div className="homeProductItemInfo">
                    <div className="homeProductItemCode">Код: {item.SKU}</div>
                    <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                    <div className="homeProductItemPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                    <div className="catProductBtn">
                    {item.qty > 0 ? <button
                                className="catProdAdd cartBtnProduct tooltip2"
                                onClick={() => handleAddToCart(item, 1)}
                                data-tooltip="Корзина"
                            ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button> : <button
                                className="catProdAddRed cartBtnProduct notRegistered tooltip3"
                                data-tooltip="Под заказ"
                                disabled
                            ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button>}


                            <button
                                onClick={() => handleAddToFav(item)}
                                className="catProdAdd favBtnProduct tooltip3"
                                data-tooltip={isItemInFavourite ? "Убрать из избранного" : "Избранное"}
                            ><i className="fa-solid fa-heart"></i></button>

                        <button
                            className="catProdAdd compBtnProduct tooltip2"   
                            data-tooltip="Сравнить"                         
                        ><i className="fas fa-balance-scale" aria-hidden="true"></i></button>
                    </div>
                    <div className="productBackground"></div>
                </div>
                </div>
            </div>
        )
    });

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    speed: 500,
                    autoplay: true,
                    centerPadding: '10px',
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings}>
                {productList}
            </Slider>
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </div>
    );
}

export default NewsProduct;
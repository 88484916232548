import React from 'react';

function Page404 () {
    return (
        <div className='container'>             
            <h1>Страница 403 | Доступ запрещен</h1>
            <h3>Доступ запрещен так как Вы не администратор</h3>
        </div>
    )
}

export default Page404;
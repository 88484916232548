import React, { useEffect, useState } from 'react'
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { ListGroup, Row, Col, Image } from "react-bootstrap";


const OneOrder = (props) => { // All comes from parent URL
    const [order, setOrder] = useState([]);

    useEffect(() => {

        const orderId = props.match.params.id;
        
        axios.get(`/api/order/${orderId}`).then(res=>{
            if(res.data.status === 200){
                setOrder(res.data.orderItems);
            } else if(res.data.status === 404){

            }
        });

    }, [props.match.params.id]);

    var orderId = props.match.params.id;
    var $order = '';

    $order = order.map((item, idx) => {
        return(
            <ListGroup.Item key={idx} >                
                <Row>
                    <Col md={2}>
                        <Image src={`/uploads/productsthumb/${item.image}`} alt={item.name} fluid rounded />
                    </Col>
                    <Col md={6}>
                        <h3>{item.name}</h3>
                    </Col>
                    <Col md={1}>
                        <h3>{item.qty}</h3>
                    </Col>
                    <Col md={1}>
                        <div className="cartProdPrice">{item.price} ₸</div>
                    </Col>
                    <Col md={2}>
                        <div className="cartProdTotalPrice">{item.price * item.qty} ₸</div>
                    </Col>
                </Row>
            </ListGroup.Item>
        )
    });


  return (
    <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Содержимое заказа № {orderId} | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content='Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция' />
                <meta name="description" content='Содержимое заказа ✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀' />
                <meta property="og:type" content="article" />
                <meta property="og:title" content='Содержимое заказа № | Horecamarket Market'  />
                <meta property="og:description" content='Содержимое заказа ✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀' />
                <meta property="og:keywords" content='Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция' />
                <meta property="og:url" content={`https://horecamarket.kz/order/${orderId}`} />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content='Содержимое заказа ✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀' />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Содержимое заказа № {orderId}</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li><Link to="/dashboard">Кабинет</Link></li>
                                /
                                <li>Заказ № {orderId}</li>
                            </ul>
                        </div>
                        <div className="orderContent">
                            {$order}
                        </div>
                        <Link className='btn btn-success btn-sm mt-4' to="/dashboard">Назад в кабинет</Link>
                    </div>
                  </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
  )
}

export default OneOrder

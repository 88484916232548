import React, { useState, useEffect } from "react";
import axios from "axios";


function HomeAboutCompany() {
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        axios.get(`/api/view-settings`).then(res => {
            if (res.data.status === 200) {
                setSettings(res.data.setting);
            }
        }).catch();
    }, []);

    return (
        <div dangerouslySetInnerHTML={{__html: settings.banner}}>
            
        </div>
    )
}

export default HomeAboutCompany;
import React from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";
import { ListGroup, Row, Col, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { removeFromCart, decreaseCart, clearCart, getTotals, increaseCart, setQty } from "../../features/cartSlice";
import NumberFormat from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import {Helmet} from "react-helmet";
import 'react-toastify/dist/ReactToastify.css';


function Cart(){
    useEffect(() =>{
        window.scrollTo(0, 0);
    }, []);

    const cart = useSelector ((state) => state.cart);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch])

    const handleRemoveFromCart = (cartItem) => {
        dispatch(removeFromCart(cartItem));
    }

    const handleDecreaseCart = (cartItem) => {
        dispatch(decreaseCart(cartItem));
    }

    const handleIncreaseCart = (cartItem) => {
        dispatch(increaseCart(cartItem));
    }

    const handleClearCart = () => { 
        dispatch(clearCart());
    }

    const handleSetQty = (cartItem, cartQty) => {
        const newItem = {...cartItem, cartQty: parseInt(cartQty)};
        dispatch(setQty(newItem));
    }

    
    return (
    <div className="root">
        <Helmet>
            <meta charset="utf-8" />
            <title>Корзина. Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
            <link rel="icon" href="/uploads/design/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
            <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
            <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
            <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
            <meta property="og:url" content="https://horecamarket.kz/" />
            <meta property="og:locale" content="ru_RU" />
            <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
        </Helmet>  
        <main>
            <Header />
            <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Корзина</h1>
                    <div className="bread">
                        <ul>
                            <li><Link to="/">Главная</Link></li>
                            /
                            <li>Корзина</li>                               
                        </ul>
                    </div>
                    <div className="cartContentWrap">
                        <ListGroup>
                            {cart.cartItems.length === 0 ? (
                                <div className="cart-empty">
                                    <p>Ваша корзина пуста!</p>
                                    <i className="fas fa-cart-arrow-down" aria-hidden="true"></i>
                                    <Link to="/">Начать покупки</Link>
                                </div>
                            ):(
                                <div>
                                    {
                                        cart.cartItems?.map(cartItem=>{
                                            return(
                                                <ListGroup.Item key={cartItem.id} >
                                                    <Row>
                                                        <Col md={2}>
                                                            <Link to={`/catalog/${cartItem.searchCategory.slug}/${cartItem.slug}`}>
                                                                <Image src={`uploads/productsthumb/${cartItem.image}`} alt={cartItem.name} fluid rounded />
                                                            </Link>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Link to={`/catalog/${cartItem.searchCategory.slug}/${cartItem.slug}`}><h3>{cartItem.name}</h3></Link>                                                            
                                                        </Col>
                                                        <Col md={2}>
                                                        {cartItem.selling_price
                                                        ?
                                                        <div>
                                                            <div className="cartProdPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cartItem.selling_price)} /> ₸</div>                                                            
                                                            <div className="cartProdPriceSale"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cartItem.price)} /> ₸</div>
                                                        </div>
                                                        :
                                                        <div className="cartProdPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cartItem.price)} /> ₸</div>
                                                        }
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="cartProdQty">
                                                                <button className="cartMinus" onClick={() => handleDecreaseCart(cartItem)}>-</button>
                                                                <input className="cartCountInp" value={cartItem.cartQuantity} min={1} type={'number'} onChange={(e) => handleSetQty(cartItem, e.target.value)} />
                                                                <button className="cartPlus" onClick={() => handleIncreaseCart(cartItem)}>+</button>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Button
                                                            type="button"
                                                            variant="danger"
                                                            onClick={() => handleRemoveFromCart(cartItem)}
                                                            ><i className="fas fa-trash-alt"></i></Button>
                                                        </Col>
                                                        <Col md={2}>
                                                            {cartItem.selling_price
                                                            ?
                                                            <div className="cartProdTotalPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cartItem.selling_price) * cartItem.cartQuantity} /> ₸</div>
                                                            :
                                                            <div className="cartProdTotalPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cartItem.price) * cartItem.cartQuantity} /> ₸</div>
                                                            }                                                            
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                    
                                    <div className="cartSummaryWrap">                        
                                        <span className="cartSummaryTotal">Итого: <NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cart.cartTotalAmount)} /> ₸</span>
                                        <div className="cartSummaryBtns">
                                            <button variant="info"  onClick={() => handleClearCart()} >Очистить корзину</button>                                        
                                            <Link to="/checkout" className="cartCheckBtn"><button variant="success" ><i className="fas fa-money-bill-wave fa-sm" aria-hidden="true"></i> Оформить заказ</button></Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ListGroup>
                    </div>
                </div>
            </div>
            <div className="hFooter"></div>
        </main>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <Footer />
    </div>
    );
}

export default Cart;
import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import HomeSlider from '../../components/frontend/HomePage/HomeSlider';
import HomeBests from '../../components/frontend/HomePage/HomeBests';
import HomeBrands from '../../components/frontend/HomePage/HomeBrands';
import HomeSlickCategories from '../../components/frontend/HomePage/HomeSlickCategories';
import HomeAboutCompany from "../../components/frontend/HomePage/HomeAboutCompany";
import HomeBanner from "../../components/frontend/HomePage/HomeBanner";
import HomeEmailSubmit from "../../components/frontend/HomePage/HomeEmailSubmit";
import {Helmet} from "react-helmet";

function Home(){
    useEffect(() => {
        window.scrollTo({top: 1, left: 0, behavior: 'smooth' });       
    }, []);
    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content="https://horecamarket.kz/" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <HomeSlider />
                    <HomeBests />
                    <HomeBrands />
                    <div className="homeTrendsWrap">
                        <HomeSlickCategories />
                    </div>   
                    <div className="homeBannerMail">
                        <HomeBanner />
                        <HomeEmailSubmit />
                    </div>
                    {/* Тут новости */}
                    <HomeAboutCompany />
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Home;
import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddBrand() {
    const [brandInput, setBrand] = useState({
        name: '',
        image: '',
    }); //Get all input data
    const [allCheckbox, setAllCheckbox] = useState('');


    const handleInput = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setBrand({ ...brandInput, [e.target.name]: e.target.value });
    }


    const handleCheckbox = (e) => {
        e.persist();
        setAllCheckbox({ ...allCheckbox, [e.target.name]: e.target.checked });
        console.log(allCheckbox)
    }


    const submitCategory = (e) => {
        e.preventDefault();

        const data = {
            name: brandInput.name,
            image: brandInput.image,
            //status: allCheckbox.status  ? '1' : '0',
        }

        

        axios.post(`/api/store-brand`, data).then(res => {
            if (res.data.status === 200) {
                swal("Бренд успешно создан!", res.data.message, 'success');
                document.getElementById('BRAND_FORM').reset();
            } else if (res.data.status === 400) {
                toast.error('Не все поля заполнены');
                setBrand({ ...brandInput, error_list: res.data.errors }); //Записываем ошибки в массив
            }
        });
    }
    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Добавить бренд</h1>
            <form onSubmit={submitCategory} id="BRAND_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Название бренда<span className="red">*</span></b></label>
                            <input type="text" required name="name" onChange={handleInput} value={brandInput.name} className="form-control" />
                            <p className="red">Бренд должен называться в точности так же, как у товара!</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Адрес картинки<span className="red">*</span></b></label>
                            <input type="text" required name="image" onChange={handleInput} value={brandInput.image} className="form-control" />
                        </div>

                        {/* <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleCheckbox} defaultChecked={allCheckbox.status === '1' ? true : false} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включен / есть галочка = отключен</p>
                        </div> */}
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Сохранить бренд</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default AddBrand

import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';

function AddSlide() {
    const [slideInput, setSlide] = useState({
        image: '',
        link: '',
        status: '',
    }); //Get all input data

    const handleInput = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setSlide({ ...slideInput, [e.target.name]: e.target.value });
    }

    const submitCategory = (e) => {
        e.preventDefault();

        const data = {
            link: slideInput.link,
            image: slideInput.image,
            //status: slideInput.status,
        }

        axios.post(`/api/store-slide`, data).then(res => {
            if (res.data.status === 200) {
                swal("Слайд успешно создан!", res.data.message, 'success');
                document.getElementById('SLIDE_FORM').reset();
            }
        });
    }
    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Добавить слайд</h1>
            <form onSubmit={submitCategory} id="SLIDE_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Путь к картинке слайда<span className="red">*</span></b></label>
                            <input type="text" required name="image" onChange={handleInput} value={slideInput.image} className="form-control" />
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Сслыка для слайда</b></label>
                            <input type="text" name="link" onChange={handleInput} value={slideInput.link} className="form-control" />
                        </div>

                        {/* <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleInput} value={slideInput.status} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включен / есть галочка = отключен</p>
                        </div> */}
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Сохранить слайд</button>
            </form>
        </div>
    )
}

export default AddSlide

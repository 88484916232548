import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";



function UserPassword() {
    const [passwordInput, setPassword] = useState([]);

    const handleInput = (e) => {
        e.persist();
        setPassword({ ...passwordInput, [e.target.name]: e.target.value });
    }

    const updatePassword = (e) => {
        e.preventDefault();

        const data = {
            new_password: passwordInput.new_password,
            new_confirm_password: passwordInput.new_confirm_password,
        }

        
        axios.post(`/api/changePassword`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Пароль изменен', res.data.message, 'success');
            } else if (res.data.status === 420) {
                setPassword([]);
            }
        });
    }

  return (
    <div className="root">
        <main>
            <Header />
            <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Смена пароля</h1>
                    <div className="bread">
                        <ul>
                            <li><Link to="/">Главная</Link></li>
                            /
                            <li><Link to="/dashboard">Кабинет</Link></li>
                            /
                            <li>Смена пароля</li>                        
                        </ul>
                    </div>
                    <div className='commonPagesContent'>
                        <div class="card-body">
                            <form onSubmit={updatePassword}>      
        
                                <div class="form-group row">
                                    <label for="password" className="col-md-4 col-form-label text-md-right">Новый пароль</label>
        
                                    <div class="col-md-6">
                                        <input 
                                            id="new_password" 
                                            onChange={handleInput} 
                                            value={passwordInput.new_password} 
                                            type="password" 
                                            class="form-control" 
                                            required
                                            name="new_password"
                                        />
                                    </div>
                                </div>
        
                                <div class="form-group row mt-2">
                                    <label for="password" className="col-md-4 col-form-label text-md-right">Повторите пароль</label>
            
                                    <div class="col-md-6">
                                        <input 
                                            id="new_confirm_password"
                                            onChange={handleInput}
                                            value={passwordInput.new_confirm_password}
                                            type="password"
                                            className="form-control"
                                            required
                                            name="new_confirm_password"
                                        />
                                    </div>
                                </div>
        
                                <div class="form-group row mb-0 mt-2">
                                    <div class="col-md-8 offset-md-4">
                                        <button type="submit" className="btn btn-primary">
                                            Обновить пароль
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                    <Link className='btn btn-success btn-sm mt-4' to="/dashboard">Назад в кабинет</Link>
                </div>
            </div>
            <div className="hFooter"></div>
        </main>
        <Footer />
    </div>
  )
}

export default UserPassword

import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import {Helmet} from "react-helmet";

const DashBoard = () => {
  const history = useHistory();
  const [order, setOrder] = useState([]);

  useEffect(() => {
      let isMounted = true;
      window.scrollTo(0, 0);
      document.title = 'Личный кабинет пользователя';

      axios.get(`/api/orders`).then(res => {
          if(isMounted){
            if(res.data.status === 200){
              setOrder(res.data.order)
            }
          }  
      });
      return () => {
          isMounted = false;
      }
  }, []);

  var display_orders = '';

  display_orders = order.map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.status === 'ordered' ? 'Заказ оформлен' : '' || item.status === 'canceled' ? 'Отменен' : '' || item.status === 'delivered' ? 'Доставлен' : '' || item.status === 'paid' ? 'Оплачен' : '' || item.status === 'delivery' ? 'Доставка' : ''}</td>
                    <td><strong>{item.total} ₸</strong></td>
                    <td>{item.created_at.slice(0, 10)}</td>
                    <td>
                        <Link to={`/order/${item.id}`} className="btn btn-success btn-sm">Смотреть</Link>
                    </td>
                </tr>
            )
        });

  return (
    <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Личный кабинет. Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content="https://horecamarket.kz/" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <div className='bashboardTopMenu'>
                            <h1>Личный кабинет</h1>
                            <div className='dashBoardTopBtns'>
                                <Link className='btn btn-primary btn-sm mr-2' to="/dashboard/profile">Профиль пользователя</Link>
                                <Link className='btn btn-primary btn-sm' to="/dashboard/password-update">Сменить пароль</Link>
                            </div>
                        </div>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Личный кабинет</li>
                            </ul>
                        </div>
                        <div className="checkoutContent">
                        <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Статус</th>
                                <th>Сумма заказа</th>
                                <th>Дата</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {display_orders}
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                  </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
  )
}

export default DashBoard;

import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";

function UserProfile() {
    const [profileInput, setProfileInput] = useState({
        phone: '',
        address: '',
    });
    const [profileInputName, setProfileInputName] = useState({
        name: '',
    });

    useEffect(() => {
        axios.get(`/api/getName`).then(res => {
            if (res.data.status === 200) {
                setProfileInputName(res.data);
            }
        }).catch();
    }, []);

    useEffect(() => {
        axios.get(`/api/profile`).then(res => {
            if (res.data.status === 200) {
                setProfileInput(res.data.profile);
            }
        }).catch();
    }, []);

    const handleInput = (e) => {
        e.persist();
        setProfileInput({ ...profileInput, [e.target.name]: e.target.value });
    }

    const handleInputName = (e) => {
        e.persist();
        setProfileInputName({ ...profileInputName, [e.target.name]: e.target.value });
    }


    const updateProfile = (e) => {
        e.preventDefault();

        const data = {
            name: profileInputName.name,
            phone: profileInput.phone,
            address: profileInput.address,
        };

        axios.put(`/api/profile-update`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Профиль успешно обновлен', res.data.message, 'success');
            }
        });
    }

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Профиль пользователя</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li><Link to="/dashboard">Кабинет</Link></li>
                                /
                                <li>Профиль пользователя</li>
                            </ul>
                        </div>
                        <div className='commonPagesContent'>
                            <form onSubmit={updateProfile}>
                                <div className="form-group mb-3">
                                    <label className="mb-2 mt-2"><b>Имя:</b></label>
                                    <input type="text" name="name" onChange={handleInputName} value={profileInputName.name} className="form-control" />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="mb-2 mt-2"><b>Телефон:</b></label>
                                    <input type="text" name="phone" onChange={handleInput} value={ profileInput.phone === null ? '' : profileInput.phone} className="form-control" />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="mb-2 mt-2"><b>Адрес:</b></label>
                                    <input type="text" name="address" onChange={handleInput} value={profileInput.address === null ? '' : profileInput.address} className="form-control" />
                                </div>

                                <button type="submit" className="btn btn-primary px-4 float-end mt-2">Обновить</button>
                            </form>
                        </div>
                        <Link className='btn btn-success btn-sm mt-4' to="/dashboard">Назад в кабинет</Link>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    )
}

export default UserProfile

import { Link } from "react-router-dom";

const HeaderMenuItem = ({ categories }) => {
    return (
        <ul className="dropdown">
            {categories.map((submenu, index) => (
                <li key={index} className="headerMenuItems">
                    <Link to={`/catalog/${submenu.slug}`}>{submenu.name}</Link>
                </li>
            ))}
        </ul>
    );
};

export default HeaderMenuItem;
import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToFav } from "../../features/favSlice";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../features/cartSlice";
import Skeleton from "./skeleton";
import CategoryImage from "./CategoryImage";
import NumberFormat from 'react-number-format';

function SearchResult (props) {
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {
        const newItem = { ...item, quantity: qty };
        dispatch(addToCart(newItem));
    }
    const favourite = useSelector((state) => state.fav.favItems);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [searchWord, setSearchWord] = useState('');
    const [searchError, setSearchError] = useState('');
    const [productCalc, setProductCalc] = useState([]); //Products calc in one brand
    
    useEffect(() => {
        setSearchWord(props.match.params.searchWord);
        
        axios.get(`/api/searchresult/${searchWord}`).then(res => {
            if (res.data.status === 200) {
                setProduct(res.data.products);
                setProductCalc(res.data.products.length);
                setLoading(false);
                setSearchError('');
            } else if(res.data.status === 404){
                setLoading(false);
                setProduct([]);
                setSearchError(res.data.message)
            }
        });
    }, [props.match.params, searchWord]);
    
    var showProductList = '';
    if(loading){
        showProductList = <Skeleton type="feed" />;
    }else if (product.length > 0){
        showProductList = product.map((item, idx) => {
            const isItemInFavourite = favourite.some(items => items.id === item.id);
            if(item.searchCategory === null){}else{
            return(
                <div className="homeProductItem" key={idx}>                        
                    <div className="productFront">
                        <div className="homeProductItemImg">
                            <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`}>
                                <img src={`/uploads/productsthumb/${item.image}`} className="productImage" alt={item.title} />
                                {item.qty === 0 ? <div className="instockSheeld notInStock">Под заказ</div> : <div className="instockSheeld inStock">В наличии</div>}
                            </Link>
                            {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                        </div>
                        
                        <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`}><h2 className="productH2">{item.name}</h2></Link>
                        
                        <div className="homeProductItemInfo">
                            <div className="homeProductItemCode">Код: {item.SKU}</div>
                            <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                            {item.selling_price 
                            ?
                            <div>
                                <div className="homeProductItemPrice red"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.selling_price)} /> <span><strong>₸</strong></span></div>
                                <div className="homeProductItemPriceSale"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                            </div>
                            :
                            <div className="homeProductItemPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                            }
                        </div>
                        <div className="catProductBtn">
                            {item.qty > 0 ? <button
                                className="catProdAdd cartBtnProduct tooltip2"
                                onClick={() => handleAddToCart(item, 1)}
                                data-tooltip="Корзина"
                            ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button> : <button
                                className="catProdAddRed cartBtnProduct notRegistered tooltip3"
                                data-tooltip="Под заказ"
                                disabled
                            ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button>}
                            <button
                                onClick={() => handleAddToFav(item)}
                                className="catProdAdd favBtnProduct tooltip3"
                                data-tooltip={isItemInFavourite ? "Убрать из избранного" : "Избранное"}
                            ><i className="fa-solid fa-heart"></i></button>
                            <button
                                className="catProdAdd compBtnProduct tooltip2"   
                                data-tooltip="Сравнить"                         
                            ><i className="fas fa-balance-scale" aria-hidden="true"></i></button>
                        </div>
                        <div className="productBackground"></div>
                    </div>                                                
                </div>           
            )} //End return
        });
    }
    
    return (
        <div className="root">
        <main>
            <Header />
            <div className="container">   
                <div className="oneCategoryWrap">
                    <h1><p>Результаты поиска: {searchWord}</p><span>Товаров: {productCalc}</span></h1>
                    <div className="categoryNavigatinoWrap">
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Результаты поиска: {searchWord}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="searchContentWrap">
                        {showProductList ? showProductList : <h2>{searchError}</h2>  }                        
                    </div>
                </div>
            </div>
            <div className="hFooter"></div>                
        </main>
        <Footer />
        <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    </div>
    )
}

export default SearchResult
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ViewBrand() {
    const [loading, setLoading] = useState(true);
    const [brandList, setBrandList] = useState([]);
    const [value, setValue] = useState('');

    useEffect( () => {
        axios.get(`/api/view-brand`).then(res => {
            if(res.data.status === 200){
                setBrandList(res.data.brand);
            }
            setLoading(false);
        }).catch();
    }, []);
    let viewbrand_HTMLTABLE = '';

    if(loading){
        return <h4>Загрузка брендов...</h4>
    }else{
        viewbrand_HTMLTABLE = brandList.filter((brand) => brand.name.toLowerCase().includes(value)).map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.image}</td>
                    <td>{item.status == 0 ? 'Включен' : 'Выключен'}</td>
                    <td>
                        <Link to={`/admin/edit-brand/${item.id}`} className="btn btn-success btn-sm">Редактировать</Link>
                    </td>
                </tr>
            )
        });
    }


  return (
    <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                <div className="col-sm"><h4>Все бренды</h4></div>
                <div className="col-sm">
                    <form>
                        <div class="input-group">
                            <input 
                                type="search"
                                className="form-control rounded"
                                placeholder="Поиск названию бренда"
                                aria-label="Search"
                                aria-describedby="search-addon"
                                value={value}
                                onChange={(e) => setValue(e.target.value)} 
                            />                                    
                        </div>
                    </form>
                </div>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Картинка</th>
                                <th>Статус</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewbrand_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  )
}

export default ViewBrand

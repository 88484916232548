import React, { Fragment } from "react";

function HomeEmailSubmit() {
    return (
        <Fragment>
            <div className="HomeEmailSubmit">
                <h4>Хотите персональную скидку?<br/>Подпишитесь на рассылку<br/>новостей и акций</h4>
                <div className="HomeEmailSubmitIcon">
                    <i className="fa-solid fa-envelope-open-text"></i>
                </div>
                <form>
                    <div className="HomeEmailSubmitInput">        
                        <input type="text" name="mail_submit" required placeholder="Введите свой e-mail" />
                    </div>  
                    <button>отправить</button>
                </form>
            </div>
        </Fragment>
    )
}

export default HomeEmailSubmit;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ViewProduct() {
    const [loading, setLoading] = useState(true);
    const [productList, setProductList] = useState([]);
    const [value, setValue] = useState('');

    useEffect( () => {
        axios.get(`/api/view-products`).then(res => {
            if(res.data.status === 200){
                setProductList(res.data.products);
            }
            setLoading(false);
        }).catch();
    }, []);
    let viewproducts_HTMLTABLE = '';

    if(loading){
        return <h4>Загрузка товаров...</h4>
    }else{
        viewproducts_HTMLTABLE = 
        productList.filter((product) => 
        product.SKU.toLowerCase().includes(value) ||
        product.name.toLowerCase().includes(value)
    ).map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.SKU}</td>
                    <td>{item.selling_price}</td>
                    <td>{item.remark}</td>
                    <td>{item.status == 0 ? 'Включен' : 'Выключен'}</td>
                    <td>
                        <Link to={`/admin/edit-product/${item.id}`} className="btn btn-success btn-sm">Редактировать</Link>
                    </td>
                </tr>
            )
        });
    }

  return (
    <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                <div className="col-sm"><h4>Все товары</h4></div>
                <div className="col-sm">
                    <form>
                        <div class="input-group">
                            <input 
                                type="search"
                                class="form-control rounded"
                                placeholder="Поиск по коду или названию товара"
                                aria-label="Search"
                                aria-describedby="search-addon"
                                value={value}
                                onChange={(e) => setValue(e.target.value)} 
                            />                                    
                        </div>
                    </form>
                </div>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Код</th>
                                <th>Цена распродажи</th>
                                <th>Тренд</th>
                                <th>Статус</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewproducts_HTMLTABLE.slice(0, 10)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  )
}

export default ViewProduct

import React, { useState, useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";



function Login() {
    useEffect(() =>{
        window.scrollTo(0, 0);
        document.title = 'Авторизация пользователя - Интернет магазин Horeca Market';
    }, [])

    const history = useHistory();

    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();

        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }
    
    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }
        
        axios.get('/sanctum/csrf-cookie').then(response => { 
            axios.post(`api/login`, data).then(res => {
                if(res.data.status === 200){
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('role', res.data.role);
                    history.push('/');
                    swal("Horeca Market", res.data.message, 'success');                    
                    if(res.data.role === 'admin'){
                        history.push('/admin/dashboard');
                    }else{
                        history.push('/');
                    }
                }else if (res.data.status === 401) {
                    toast.error('Не верный логин или пароль');
                }else{
                    setLogin({...loginInput, error_list: res.data.validation_errors});
                }
            });
        });
    }    

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Вход</h1>
                    <div className="bread">
                        <ul>
                            <li><Link to="/">Главная</Link></li>
                            /
                            <li>Вход</li>                               
                        </ul>
                    </div>
                    <div className="formMainAuth">
                        <form onSubmit={loginSubmit} autoComplete="off">
                            <fieldset className="inputField">
                                <i class="fa-solid fa-envelope"></i>
                                <input 
                                    type="email" 
                                    required 
                                    name="email" 
                                    onChange={handleInput} 
                                    value={loginInput.email} 
                                    placeholder="Ваш email"
                                    className="modalInput" 
                                />
                            </fieldset><br/>
                            <fieldset className="inputField">
                                <i class="fa-solid fa-key"></i>
                                <input 
                                    type="text" 
                                    required 
                                    name="password" 
                                    placeholder="*************"
                                    onChange={handleInput} 
                                    value={loginInput.password} 
                                    className="modalInput" 
                                />
                            </fieldset>
                            <div className="forgotPassword">
                                <Link to="/forgot-password">Забыли пароль?</Link>
                            </div>
                            <div className="formMainAuthField">
                                <button type="submit"  className="modalInputBtn">Вход</button>
                            </div>
                        </form>
                        <img src="/uploads/design/login.jpg" alt="" />
                    </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            <Footer />
        </div>
    );
}

export default Login;
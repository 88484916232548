import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ViewSection() {
    const [loading, setLoading] = useState(true);
    const [sectionList, setSectionList] = useState([]);

    useEffect( () => {
        axios.get(`/api/view-section`).then(res => {
            if(res.data.status === 200){
                setSectionList(res.data.section);
            }
            setLoading(false);
        }).catch();
    }, []);
    let viewsections_HTMLTABLE = '';

    if(loading){
        return <h4>Загрузка секций...</h4>
    }else{
        viewsections_HTMLTABLE = 
        sectionList.map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                        <Link to={`/admin/edit-section/${item.id}`} className="btn btn-success btn-sm">Редактировать</Link>
                    </td>
                </tr>
            )
        });
    }

  return (
    <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                    <h4>Все секции</h4>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewsections_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  )
}

export default ViewSection

import React from "react";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import SaleProduct from './SaleProduct';
import NewsProduct from './NewsProduct';
import BestProduct from './BestProduct';
import FeaturedProduct from './FeaturedProduct';
import { Button } from "bootstrap";
import { Link } from "react-router-dom";

function HomeSlickCategories(){
    return (
        <TabsUnstyled defaultValue={0}>
            <TabsListUnstyled className="homeTabs">
              <TabUnstyled className="homeTabsBtn">Акции</TabUnstyled>
              <TabUnstyled className="homeTabsBtn">Новинки</TabUnstyled>
              <TabUnstyled className="homeTabsBtn">Хиты продаж</TabUnstyled>
              {/* <TabUnstyled className="homeTabsBtn">Рекомендуемые</TabUnstyled> */}
            </TabsListUnstyled>
            <TabPanelUnstyled className="homeTabsCat" value={0}>
              <SaleProduct/>              
              <div className="homeSaleAllBtn">
                <Link to={'/sale'} >Все акции</Link>
              </div>
            </TabPanelUnstyled>
            <TabPanelUnstyled className="homeTabsCat" value={1}>
              <NewsProduct/>
            </TabPanelUnstyled>
            <TabPanelUnstyled className="homeTabsCat" value={2}><BestProduct/></TabPanelUnstyled>
            {/* <TabPanelUnstyled className="homeTabsCat" value={3}><FeaturedProduct/></TabPanelUnstyled> */}
          </TabsUnstyled>
    );
}

export default HomeSlickCategories;
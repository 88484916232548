import React from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";


function Compare(){
    return (
        <div className="root">
            <main>
            <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Сравнение</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Сравнить товары</li>                               
                            </ul>
                        </div>
                        <div className="favContentWrap">

                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Compare;
import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";

function Home(){
    useEffect(() =>{
        document.title = 'Новости компании - Интернет магазин Horeca Market';
    }, [])

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Новости компании</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Новости компании</li>                               
                            </ul>
                        </div>
                        <div className='commonPagesContent'>
                        Предназначен для стирки вещей из белых тканей или тканей пастельных цветов. Благодаря активной формуле, устраняет даже очень стойкие загрязнения от краски для волос, пальчиковых красок, текстовых маркеров и ржавчины. Эффективно отстирывает даже самые сложные пятна. Отбеливающие компоненты, придают ткани безупречную белизну и не повреждают волокна. Средство моющее синтетическое универсальное, обладает пониженным пенообразованиемю. Предназначено для стирки в машинах любого типа. Вес - 14 кг.
                        <br /><br />
                        Предназначен для стирки вещей из белых тканей или тканей пастельных цветов. Благодаря активной формуле, устраняет даже очень стойкие загрязнения от краски для волос, пальчиковых красок, текстовых маркеров и ржавчины. Эффективно отстирывает даже самые сложные пятна. Отбеливающие компоненты, придают ткани безупречную белизну и не повреждают волокна. Средство моющее синтетическое универсальное, обладает пониженным пенообразованиемю. Предназначено для стирки в машинах любого типа. Вес - 14 кг.
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Home;
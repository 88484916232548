import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../features/cartSlice";
import { addToFav } from "../../features/favSlice";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";



function Favourite(){
    useEffect(() =>{
        window.scrollTo(0, 0);
    }, []);

    const favourite = useSelector ((state) => state.fav);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }

    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {
        const newItem = { ...item, quantity: qty };
        dispatch(addToCart(newItem));
        dispatch(addToFav(item));
    }

    let productList = '';
    productList = favourite.favItems.map((item, idx) => {
        return (
            <div className="homeProductItem" key={idx}>
                <div className="productFront">
                    <div className="homeProductItemImg">
                    <Link to={`catalog/${item.category}/${item.slug}`}>
                        <img src={`/uploads/productsthumb/${item.image}`} className="productImage" alt={item.title}  height={230} width={230} />
                        </Link>
                    </div>
                    <Link to={`catalog/${item.category}/${item.slug}`}><h2>{item.name}</h2></Link>
                    <div className="homeProductItemInfo">
                        <div className="homeProductItemCode">Код: {item.SKU}</div>
                        <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                        <div className="homeProductItemPrice">{item.price.split('.')[0]} <span><strong>₸</strong></span></div>                    
                    </div>
                    <div class="catProductBtn favProdBtns">
                        <button class="catProdAdd favRemoveBtn cartBtnProduct tooltip2" onClick={() => handleAddToFav(item)} data-tooltip="Удалить"><i className="fas fa-trash-alt"></i></button>
                        {item.qty === 0 ? <div className="favNotAviable">Под заказ</div> : <div className="favMoveToCart" onClick={() => handleAddToCart(item, 1)}>Переместить в корзину</div>}
                    </div>
                    <div className="productBackground"></div>
                </div>             
            </div>
        )
    });

    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Избранное. Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content="https://horecamarket.kz/" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
            <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Избранное</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Избранное</li>                               
                            </ul>
                        </div>
                        <div className="wishlistContent">
                            {favourite.favItems.length === 0 
                            ? 
                            <div className="wishlistWrap"><h2>В избранном ничего нет</h2><i className="fas fa-heart-broken" aria-hidden="true"></i><Link to="/">Начать покупки</Link></div> 
                            :
                            <div className="favContentWrap">{productList}</div> }
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Favourite;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ViewNews() {
  const [loading, setLoading] = useState(true);
  const [newsList, setNewsList] = useState([]);

  useEffect( () => {
      axios.get(`/api/view-news`).then(res => {
          if(res.data.status === 200){
            setNewsList(res.data.news);
          }
          setLoading(false);
      }).catch();
  }, []);

  let viewnews_HTMLTABLE = '';

  if(loading){
      return <h4>Загрузка новостей...</h4>
  }else{
    viewnews_HTMLTABLE = 
    newsList.map((item) => {
          return(
              <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td><img src={`/${item.image}`} alt={item.title} /></td>
                  <td>{item.slug}</td>
                  <td>{item.status == 0 ? 'Включен' : 'Выключен'}</td>
                  <td>
                      <Link to={`/admin/edit-news/${item.id}`} className="btn btn-success btn-sm">Редактировать</Link>
                  </td>
              </tr>
          )
      });
  }

  return (
    <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                  <div className="col-sm"><h4>Все новости</h4></div>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Заголовок</th>
                                <th>Картинка</th>
                                <th>Транслит</th>
                                <th>Статус</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewnews_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  )
}

export default ViewNews

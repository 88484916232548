import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function Modal( { closeModal } ) {   
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        await axios.post(`https://horecamarket.kz/mail/callback.php`, data).then(response => {
            console.log(response.status)
            if(response.status === 200){
                toast.success('Ваша заявка принята!');
                closeModal(false);
            }
        })
        .catch(function (error) {
            console.log(error);
            toast.error('Заявка не отправлена!');
        });
    };
    

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <button className="modalCloseBtn" onClick={()=> closeModal(false)}>X</button>
                <h2>Заказать звонок</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="inputField">
                        <i class="fa-solid fa-user"></i>
                        <input
                            
                            required
                            className="modalInput"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Ваше имя"
                            {...register("name")}
                        />
                    </fieldset>                    
                    <fieldset className="inputField">
                        <i class="fa-solid fa-phone-volume"></i>
                        <input
                            required
                            className="modalInput"
                            type="phone"
                            id="phone"
                            name="phone"
                            placeholder="Ваш телефон"
                            {...register("phone")}
                        />
                    </fieldset>
                    <input type="submit" className="modalInputBtn" value="Отправить" />
                </form>                
            </div>
            <div className="modalOverlay" onClick={()=> closeModal(false)}></div>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    )
}

export default Modal

import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from "../../features/cartSlice";
import { addToFav } from "../../features/favSlice";
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";


function OneBrand(props){
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {
        const newItem = { ...item, quantity: qty };
        dispatch(addToCart(newItem));
    }
    const favourite = useSelector((state) => state.fav.favItems);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }

    const brand_slug = props.match.params.brand;
    const [productCalc, setProductCalc] = useState([]); //Products calc in one brand
    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios.get(`/api/getBrandProducts/${brand_slug}`).then(res => {
            if(res.data.status === 200){
                setProductCalc(res.data.oneBrand.length)
                setProducts(res.data.oneBrand);
            }
        });
    }, []);

    let productList = '';
    productList = products.map((item, idx) => {
        const isItemInFavourite = favourite.some(items => items.id === item.id);
        if(item.searchCategory === null){}else{
            return (
                <div className="homeProductItem" key={idx}>
                    <div className="productFront">
                        <div className="homeProductItemImg">
                            <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`}>
                                <img src={`/uploads/productsthumb/${item.image}`} className="productImage" alt={item.title} />
                                {item.qty === 0 ? <div className="instockSheeld notInStock">Под заказ</div> : <div className="instockSheeld inStock">В наличии</div>}
                            </Link>
                            {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                        </div>
                        <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`}><h2>{item.name}</h2></Link>
                        <div className="homeProductItemInfo">
                            <div className="homeProductItemCode">Код: {item.SKU}</div>
                            <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                            {item.selling_price 
                            ?
                            <div>
                                <div className="homeProductItemPrice red"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.selling_price)} /> <span><strong>₸</strong></span></div>
                                <div className="homeProductItemPriceSale"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                            </div>
                            :
                            <div className="homeProductItemPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                            }
                            <div className="catProductBtn">
                            {item.qty > 0 ? <button
                                    className="catProdAdd cartBtnProduct tooltip2"
                                    onClick={() => handleAddToCart(item, 1)}
                                    data-tooltip="Корзина"
                                ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button> : <button
                                    className="catProdAddRed cartBtnProduct notRegistered tooltip3"
                                    data-tooltip="Под заказ"
                                    disabled
                                ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button>}


                                <button
                                    onClick={() => handleAddToFav(item)}
                                    className="catProdAdd favBtnProduct tooltip3"
                                    data-tooltip={isItemInFavourite ? "Убрать из избранного" : "Избранное"}
                                ><i className="fa-solid fa-heart"></i></button>

                                <button
                                    className="catProdAdd compBtnProduct tooltip2"
                                    data-tooltip="Сравнить"
                                ><i className="fas fa-balance-scale" aria-hidden="true"></i></button>
                            </div>
                            <div className="productBackground"></div>
                        </div>
                    </div>
                </div>
            )
        }
    });

    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Бренд {`${brand_slug}`} .Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content={`https://horecamarket.kz/brand/${brand_slug}`} />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1><p>Бренд {brand_slug}</p><span>Товаров: {productCalc}</span></h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li><Link to="/brands">Бренды</Link></li>
                                /
                                <li>Бренд {brand_slug}</li>
                            </ul>
                        </div>
                        <div className='brandsContentWrap'>
                           {productList}
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
        </div>
    );
}

export default OneBrand;
import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';


function Setting() {
    const [loading, setLoading] = useState(true);
    const [settingsInput, setSettings] = useState([]);

    useEffect(() => {
        axios.get(`/api/view-settings`).then(res => {
            if (res.data.status === 200) {
                setSettings(res.data.setting);
            }
            setLoading(false);
        }).catch();
    }, []);

    const handleInput = (e) => {
        e.persist();
        setSettings({ ...settingsInput, [e.target.name]: e.target.value });
    }

    const updateSettings = (e) => {
        e.preventDefault();

        const data = settingsInput;
        
        axios.put(`/api/update-settings`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Настройки успешно обновлены', res.data.message, 'success');
            }
        });
    }

    if (loading) {
        return <h4>Загрузка настроек...</h4>
    } else {


        return (
            <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Настройки сайта</h1>
            <form onSubmit={updateSettings} >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                </ul>
                <div className="tab-content" id="settingContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Название сайта:</b></label>
                            <input type="text" name="site_name" onChange={handleInput} value={settingsInput.site_name} className="form-control" />
                            <p>Название над поиском</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Телефон:</b></label>
                            <input type="text" name="phone" onChange={handleInput} value={settingsInput.phone} className="form-control" />
                            <p>Телефон в шапке, футере и в разделе Контакты</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Whats App:</b></label>
                            <input type="text" name="whatsapp" onChange={handleInput} value={settingsInput.whatsapp} className="form-control" />
                            <p>Whats App в футере</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>E-mail:</b></label>
                            <input type="text" name="email" onChange={handleInput} value={settingsInput.email} className="form-control" />
                            <p>E-mail в шапке и в футере</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Адрес:</b></label>
                            <input type="text" name="address" onChange={handleInput} value={settingsInput.address} className="form-control" />
                            <p>Адрес в шапке, в футере и в разделе Контакты</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Ссылка на карту:</b></label>
                            <input type="text" name="map_link" onChange={handleInput} value={settingsInput.map_link} className="form-control" />
                            <p>Ссылка на карту 2gis или любую другую - в шапке, в футере и в разделе Контакты</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Facebook:</b></label>
                            <input type="text" name="facebook" onChange={handleInput} value={settingsInput.facebook} className="form-control" />
                            <p>Ссылка на Facebook - в футере и в разделе Контакты</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Instagram:</b></label>
                            <input type="text" name="instagram" onChange={handleInput} value={settingsInput.instagram} className="form-control" />
                            <p>Ссылка на Instagram - в футере и в разделе Контакты</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Copyright в футере:</b></label>
                            <input type="text" name="copyright" onChange={handleInput} value={settingsInput.copyright} className="form-control" />
                            <p>Copyright - в футере</p>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-2"><b>Картинка или видео банера на главной:</b></label>
                            <input type="text" name="banner" onChange={handleInput} value={settingsInput.banner} className="form-control" />
                            <p>Можно использовать тэги Img, iFrame видео с ютуба или тег Video</p>
                        </div>

                    </div>
                </div>
                <button type="submit" className="btn btn-primary px-4 float-end settingsBtn">Обновить настройки</button>
            </form>
        </div>
        )
    }
}

export default Setting;
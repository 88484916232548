import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

function HomeAboutCompany() {
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        axios.get(`/api/view-settings`).then(res => {
            if (res.data.status === 200) {
                setSettings(res.data.setting);
            }
        }).catch();
    }, []);

    return (
        <Fragment>
            <div className="homeAboutCompany">
                <h3>О компании Horeca</h3>
                <div className="homeAboutContent" dangerouslySetInnerHTML={{__html: settings.about}}></div>
            </div>
        </Fragment>
    )
}

export default HomeAboutCompany;
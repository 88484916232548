import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "@mui/material";
import {Helmet} from "react-helmet";



function Delivery(){
    useEffect(() =>{
        window.scrollTo(0, 0);
        document.title = 'Доставка и оплата - Интернет магазин Horeca Market';
    }, [])

    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Доставка и оплата. Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta name="description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:description" content="✅ Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market ☀" />
                <meta property="og:keywords" content="Оборудование для Прачечных и Химчисток, Кухонное Оборудование, Алматы, Казахстан, купить, дешево, Инвентарь для уборки пола и окон, Электрические чайники кофеварки и кофемашины, Тележки для уборки, Чистящие и моющие средства, Оборудование для уборки, Тепловое оборудование для профессиональной кухни, Бумажная продукция" />
                <meta property="og:url" content="https://horecamarket.kz/" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Оборудование для кафе и ресторанов купить Алматы оптом и в розницу. Пластиковая посуда. | Horecamarket Market" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Доставка и оплата</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Доставка и оплата</li>                               
                            </ul>
                        </div>
                        <div className='commonPagesContent deliveryPagesContent'>
                            <h4>« Доставка при заказе от 20 000 бесплатно! »</h4>
                            <div className="deliveryTitleIcon">
                                <img src='/uploads/delivery/delivery_1.jpg' alt='' />
                                <h5>Заказ и оплата</h5>
                            </div>
                            <div className="deliveryContent">
                            <p>Вы можете заказать любой товар по телефону, электронной почте или на сайте.
                            Для того, чтобы оформить заказ необходимо предоставить реквизиты и контактные данные.</p>
                            <p>Вы можете оплатить товар так, как удобнее вам:</p>
                            <ul>
                                <li><b>по безналичному расчёту</b> (товар будет доставлен или доступен для самовывоза после поступления оплаты)</li>
                                <li><b>по наличному расчёту</b> (непосредственно в магазине, наличными водителю, а также в нашем офисе)</li>
                            </ul>
                            </div>

                            <div className="deliveryTitleIcon">
                                <img src='/uploads/delivery/delivery_2.jpg' alt='' />
                                <h5>Получение товара</h5>
                            </div>
                            <div className="deliveryContent">
                            <p>Для получения товара Вам нужны паспорт или документ его заменяющий и доверенность на фирменном бланке организации с правом получения ТМЦ.</p>
                            </div>

                            <div className="deliveryTitleIcon">
                                <img src='/uploads/delivery/delivery_3.jpg' alt='' />
                                <h5>Обработка заказа</h5>
                            </div>
                            <div className="deliveryContent">
                            <p>При размещении заказа до 14:00 в будние дни доставка выполняется на следующий рабочий день, после 14:00 и/или в выходной день - доставка выполняется через один рабочий день.</p>
                            </div>

                            <div className="deliveryTitleIcon">
                                <img src='/uploads/delivery/delivery_4.jpg' alt='' />
                                <h5>Самовывоз со склада</h5>
                            </div>
                            <div className="deliveryContent">
                                <table>
                                    <thead>
                                        <th>День</th>
                                        <th>Время</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>пн - пт</td>
                                            <td>10:00 - 18:00</td>
                                        </tr>
                                        <tr>
                                            <td>сб – вс</td>
                                            <td>выходной</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="deliveryTitleIcon">
                                <img src='/uploads/delivery/delivery_5.jpg' alt='' />
                                <h5>Стоимость самовывоза</h5>
                            </div>
                            <div className="deliveryContent">
                            <p>Бесплатно.</p>
                            </div>

                            <div className="deliveryTitleIcon">
                                <img src='/uploads/delivery/delivery_6.jpg' alt='' />
                                <h5>Доставка</h5>
                            </div>
                            <div className="deliveryContent">
                            <p>Доставка всех товаров, осуществляется собственной службой доставки Компании до подъезда или зоны разгрузки силами водителя, при условии, что одно место весит не более 20 кг, а общий объём всех грузовых мест не превышает 0,25 куб. м. В противном случае, приемка груза осуществляется силами клиента с борта машины.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Delivery;
import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToFav } from "../../features/favSlice";
import { addToCart } from "../../features/cartSlice";
import { useDispatch, useSelector } from 'react-redux';
import CategoryImage from "./CategoryImage";
import Skeleton from "./skeleton";
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";


function OneCategory(props){
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {
        const newItem = {...item, quantity: qty};                
        dispatch(addToCart(newItem))
    }
    const favourite = useSelector((state) => state.fav.favItems);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }
    const [brandsCalc, setBrandsCalc] = useState(false);
    const history = useHistory();
    const [product, setProduct] = useState([]); //Products coming once from category
    const [productCalc, setProductCalc] = useState([]); //Products calc in one category
    const [checkedValues, setCheckedValues] = useState([]); //Values of brands
    const [lastPage, setLastPage] = useState(0);
    const [filteredProduct, setFilteredProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [filters, setFilters] = useState({ //Filters result in category page
        s: '',
        sort: '',
        newCheckedValues: [],
        page: 1,
    });
    

    const perPage = 12; // Defaul products per page
    

    useEffect(() => {
        window.scrollTo(0, 0);
        let isMounted = true;
        const product_slug = props.match.params.slug;
        (
            async () => { 
                await axios.get(`/api/fetchProducts/${product_slug}`).then(res => {
                    if(isMounted){
                        if(res.data.status === 200){
                            setProduct(res.data.product_data.product);
                            setProductCalc(res.data.product_data.product.length)
                            setFilteredProduct(res.data.product_data.product.slice(0, filters.page * perPage));
                            setCategory(res.data.product_data.category);
                            document.title = res.data.product_data.category.name;
                            setLastPage(Math.ceil(res.data.product_data.length / perPage));
                            setLoading(false);
                        } else if( res.data.status === 400 ){
                            toast.error('Внимание нет товаров');
                        } else if( res.data.status === 404 ){
                            history.push('/categories');
                            toast.error('Нет такой категории');
                        }
                    }
            });
        })()
        return () => {
            isMounted = false;
        }
    }, [props.match.params.slug, history ]);

    useEffect(() => {
        let products = product.filter(p => p.name.toLowerCase().indexOf(filters.s.toLowerCase()) >= 0); //Search filtering
        
        if(filters.sort === 'asc' || filters.sort === 'desc'){ //Sorting filtering
            products.sort((a, b) => {                
                const diff = a.price - b.price;
                if(diff === 0) return 0;
                const sign = Math.abs(diff) / diff;                
                return filters.sort === 'asc' ? sign : -sign;
            });
        }
        //Brands filtering
        
         if(filters.newCheckedValues.length !== 0){
            const filterData = products.filter(item => checkedValues.includes(item.brand));
            setFilteredProduct(filterData);
            //console.log(filterData);
            

            setLastPage(Math.ceil(filterData.length / perPage));

            setFilteredProduct(filterData.slice(0, filters.page * perPage));
         } else{
            setLastPage(Math.ceil(products.length / perPage));

            setFilteredProduct(products.slice(0, filters.page * perPage));
         }
        
    }, [filters]); //Use Effect only for filters

    const searchCategory = (s) => {
        setFilters({
            ...filters,
            s
        });
    };

    const sort = (sort) => {
        setFilters({
            ...filters,
            sort
        }); 
    }

    const loadItems = () => {
        setFilters({
            ...filters,
            page: filters.page + 1,
        }); 
    }

    const handleBrandChecked = e => {
        const brand = displayBrands[e.target.dataset.id];
        let newCheckedValues = checkedValues.filter((item) => item !== brand);
        if (e.target.checked) newCheckedValues.push(brand);
        setCheckedValues(newCheckedValues);

        setFilters({
            ...filters,
            newCheckedValues
        }); 
    }

    let button;
    if(filters.page !== lastPage && filteredProduct.length >= 12){
        button = (
            <div className="categoryBtnMore">
                <button onClick={loadItems}>Загрузить еще товары</button>
            </div>
        )    
    }
    var showProductList = '';
    if(loading){
        showProductList = <Skeleton type="feed" />;
    }else{
        
        showProductList = filteredProduct.map((item, idx) => { //Maping the result of filtering
            const isItemInFavourite = favourite.some(items => items.id === item.id);
            return(
                <div className="homeProductItem" key={idx}>
                    <div className="productFront">
                        <div className="homeProductItemImg">
                            <Link to={`${category.slug}/${item.slug}`}>                    
                                <CategoryImage image={item.image} title={item.name} qty={item.qty}/>
                            </Link>
                            {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                        </div>
                        <Link to={`${category.slug}/${item.slug}`}><h2 className="productH2">{item.name}</h2></Link>
                        <div className="homeProductItemInfo">
                            <div className="homeProductItemCode">Код: {item.SKU}</div>
                            <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                            {item.selling_price 
                            ?
                            <div>
                                <div className="homeProductItemPrice red"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.selling_price)} /> <span><strong>₸</strong></span></div>
                                <div className="homeProductItemPriceSale"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                            </div>
                            :
                            <div className="homeProductItemPrice"><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(item.price)} /> <span><strong>₸</strong></span></div>
                            }
                        </div>
                        <div className="catProductBtn">
                        {item.qty > 0 ? <button
                                className="catProdAdd cartBtnProduct tooltip2"
                                onClick={() => handleAddToCart(item, 1)}
                                data-tooltip="Корзина"
                            ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button> : <button
                                className="catProdAddRed cartBtnProduct notRegistered tooltip3"
                                data-tooltip="Под заказ"
                                disabled
                            ><i className="fas fa-shopping-cart" aria-hidden="true"></i></button>}


                            <button
                                onClick={() => handleAddToFav(item)}
                                className="catProdAdd favBtnProduct tooltip3"
                                data-tooltip={isItemInFavourite ? "Убрать из избранного" : "Избранное"}
                            ><i className="fa-solid fa-heart"></i></button>
                            <button
                                className="catProdAdd compBtnProduct tooltip2"   
                                data-tooltip="Сравнить"                         
                            ><i className="fas fa-balance-scale" aria-hidden="true"></i></button>
                        </div>
                        <div className="productBackground"></div>
                    </div>
                </div>
            )
        });
    }
    let displayBrands = [];
    let displaySortBrands = [];
    let displayBrandsOutput = [];
    if(loading){
        displayBrandsOutput = <Skeleton type="brand" />;
    }else{
        
        displayBrands = [...new Set(product.map((item => item.brand)))];
        
        displaySortBrands = displayBrands.sort(function (a, b) {
            return a.localeCompare(b); //using String.prototype.localCompare()
        });
        
        
        displayBrandsOutput = displaySortBrands.map((item,idx) =>{            
            return (
                <div key={idx}>
                    <input
                        type="checkbox"
                        data-id={idx}
                        id={idx}
                        onClick={handleBrandChecked}
                    />
                    <label htmlFor={idx} className="brandLabel">
                        {item}
                    </label><br />
                </div>
            )
        });        
    }
    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>{`${category.meta_title}`} | Horecamarket Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content={`${category.meta_keywords}`} />
                <meta name="description" content={`${category.meta_description}`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${category.meta_title}`} />
                <meta property="og:description" content={`${category.meta_description}`} />
                <meta property="og:keywords" content={`${category.meta_keywords}`} />
                <meta property="og:url" content={`https://horecamarket.kz/catalog/${category.slug}`} />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content={`${category.meta_description}`} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">   
                    <div className="oneCategoryWrap">
                        <h1><p>{category.name}</p><span>Товаров: {productCalc}</span></h1>
                        <div className="categoryNavigatinoWrap">
                            <div className="bread">
                                <ul>
                                    <li><Link to="/">Главная</Link></li>
                                    /
                                    <li>{category.name}</li>
                                </ul>
                            </div>           
                            <div className="categorySearch">
                                <input 
                                    type="text" id="catSeach" 
                                    onChange={e => searchCategory(e.target.value)} 
                                />
                                <label htmlFor="catSeach" className="catSeachLabel">
                                    <span className="catSeachName">Поиск по категории...</span>    
                                </label>
                            </div>
                            <div className="categoriesSort">
                                <select className="catSortSelect" onChange={e => sort(e.target.value)} >
                                    <option>По умолчанию</option>
                                    <option value="asc">По возрастанию цены</option>
                                    <option value="desc">По убыванию цены</option>
                                </select>
                                <span className="catSortSelectArr"></span>
                            </div>
                        </div>
                        <div className="categoriesContentWrap">                               
                            <div className="categoryBrandsWrap">   
                                <input type="checkbox" className="read-more-checker" id="read-more-checker" />                               
                                <h3>Фильтрация по бренду</h3>
                                <div className="categoryBrandContent">
                                    {displayBrandsOutput}
                                    <div className="bottom"></div>
                                </div>
                                <label htmlFor="read-more-checker" className="read-more-button" ></label>
                            </div>
                            <div className="categoryItemsBtnWrap">
                                <div className="categoryItemsWrap">
                                    {showProductList}
                                </div>
                                {button}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>                
            </main>
            <Footer />
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    );
}

export default OneCategory;

import React from 'react';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";
import cartReducer, { getTotals } from './assets/features/cartSlice';
import favReducer from './assets/features/favSlice';

import { hydrate, render } from "react-dom";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    fav: favReducer,
  },
})
store.dispatch(getTotals());



const AppMy = (
  <BrowserRouter>
    <Provider store={store}>
     <App />
    </Provider>
  </BrowserRouter>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(AppMy, rootElement);
} else {
  render(AppMy, rootElement);
}



reportWebVitals();

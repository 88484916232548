import React, { useState, useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import publicRoutesList from '../../routes/PublicRouteList';
import axios from "axios";



const FrontendLayout = () => {
    const [isActive, setIsActive] = useState(false);
    

    const [section, setSection] = useState([]); // API object of sections and categories
    useEffect(() => {
        
        axios.get(`/api/getSections`).then(res => {
            if (res.data.status === 200) {
                setSection(res.data.section);
            }
        });
    }, []);
        
    const handleMenu = event => {
        setIsActive(current => !current);
    }
    //Category menu
    
    let sectionListMenu = '';
    sectionListMenu = section.map((item, idx) => {
        return (
            <div className="mobileNavItem" key={idx}>                
                <ul className="accordion__content">
                    <li className="accordion__item" id={`link___${idx}`}>
                        <a href={`#link___${idx}`} className="mobileSectionItem" > 
                            <img className="mobileMenuImg" src={`/uploads/categories/${item.image}`} alt="" />
                            <p>{item.name}</p>
                        </a>
                        {item.categories.map((submenu, index) => (
                            <div key={index} className="accordion__submenu">
                                <Link to={`/catalog/${submenu.slug}`} onClick={handleMenu}>{submenu.name}</Link>
                            </div>
                        ))}
                    </li>
                </ul>
            </div>
        )
    });

    return (
        <div>
            <nav className={`mobileShow ${isActive ? 'menuActive' : ''}`}>
                <div id="menuBtnWrap" onClick={handleMenu}>
                    <a href="#" className={`menuBtn ${isActive ? 'menuIconActive' : ' '}`}></a>
                </div>
                <div id='destinationMove'>
                {sectionListMenu}
                <div className="mobileTopInfo">
                    <a href="tel:+77079953768"><i className="fas fa-phone-volume" aria-hidden="true"></i>+7 707 995 37 68</a>
                    <div className="headerTopInfoAdress">
                        <i className="fas fa-map-marker-alt" aria-hidden="true"></i><a href="https://2gis.kz/almaty/geo/9430047375173407?m=76.947804%2C43.281246%2F18.36" target="_blank" rel="noreferrer">ул. Суюнбая 66в, г. Алматы, РК</a></div>
                    <a href="mailto:info@horecamarket.kz"><i className="fas fa-envelope" aria-hidden="true"></i> info@horecamarket.kz</a>
                </div>
                </div>
            </nav>
            <div id="layoutSidenav" className={`mainContentWrap ${isActive ? 'mainContentActive' : ''}`}>            
                <Switch>
                    {publicRoutesList.map((routedata, i) => {
                        return (
                            routedata.component && (
                                <Route
                                    key={i}
                                    path={routedata.path}
                                    exact={routedata.exact}
                                    name={routedata.name}
                                    render={(props) => (
                                        <routedata.component {...props} />
                                    )}
                                />
                            )
                        )
                    })}
                </Switch>
            </div>
        </div>
    );
}

export default FrontendLayout;
import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";


function EditProduct(props) {
    const [productInput, setProduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productName, setProductName] = useState('');
    const history = useHistory();
    const [statusCheckbox, setStatusCheckbox] = useState('');

    
    useEffect(() => {  
        const product_id = props.match.params.id;

        axios.get(`/api/edit-product/${product_id}`).then(res => {
            if(res.data.status === 200){
                setProduct(res.data.product);
                setProductName(res.data.product.name);
                setStatusCheckbox(res.data.product);
                console.log(statusCheckbox);
            } else if (res.data.status === 404) {
                toast.error('Не удалось найти товар');
                history.push('/admin/view-product');
            }
            setLoading(false);
        });
    }, [props.match.params.id, history]);


    const handleCheckbox = (e) => {
        e.persist();
        setStatusCheckbox({ ...statusCheckbox, [e.target.name]: e.target.checked });
        
    }
    

    const handleInput = (e) => {
        e.persist();
        setProduct({ ...productInput, [e.target.name]: e.target.value });
    }

    const updateCategory = (e) => {
        e.preventDefault();

        const product_id = props.match.params.id;
        const data = {
            selling_price: productInput.selling_price,
            status: statusCheckbox.status ? 1 : 0, //if status === true ? 1 : 0
            remark: productInput.remark,

            meta_title: productInput.meta_title,
            meta_keywords: productInput.meta_keywords,
            meta_description: productInput.meta_description,
        };
        axios.put(`/api/update-product/${product_id}`, data).then(res=>{
            if(res.data.status === 200){
                swal ('Товар успешно обновлен', res.data.message, 'success');
            }
        });
    }

    if(loading){
        return <h4>Загрузка товара...</h4>
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Редактировать товар - {productName}</h1>
            <form onSubmit={updateCategory} id="PRODUCT_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">Теги для СЕО</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Цена распродажи</b></label>
                            <input type="text" name="selling_price" onChange={handleInput} value={productInput.selling_price ? productInput.selling_price : ''} className="form-control" />
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Тренд</b> - (sale, best, new)</label>
                            <input type="text" name="remark" onChange={handleInput} value={productInput.remark ? productInput.remark : ''} className="form-control" />
                        </div>
                        
                        <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleCheckbox} defaultChecked={statusCheckbox.status === 1 ? true : false} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включен / есть галочка = отключен</p>
                        </div>                        
                    </div>
                    <div className="tab-pane fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                        <div className="form-group mb-3 mt-5">
                            <label className="mb-2"><b>Заголовок для SEO</b></label>
                            <input type="text" name="meta_title" onChange={handleInput} value={productInput.meta_title ? productInput.meta_title : ''} className="form-control" />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ключевые слова SEO</b></label>
                            <textarea type="text" name="meta_keywords" onChange={handleInput} value={productInput.meta_keywords ? productInput.meta_keywords : ''} className="form-control" ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание SEO</b></label>
                            <textarea type="text" name="meta_description" onChange={handleInput} value={productInput.meta_description ? productInput.meta_description : ''} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )

}

export default EditProduct;
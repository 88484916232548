import React from "react";
import Navbar from './Navbar';
import Sidebar from "./Sidebar";
import { Redirect, Route, Switch} from "react-router-dom";
import routes from '../../routes/routes';



const MasterLayout = () => {
    return (
        <div className="sb-nav-fixed">
            <Navbar />
            <div id="layoutSidenav">            
                <div id="layoutSidenav_nav">
                    <Sidebar />
                </div>
                    <div id="layoutSidenav_content">    
                        <div className="container px-4">                   
                            <Switch>
                                {routes.map((route, i) => {
                                    return (
                                        route.component && (
                                            <Route
                                                key={i}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={(props) =>(
                                                    <route.component {...props} />
                                                )}
                                            />
                                        )
                                    )
                                })} 
                                <Redirect from="/admin" to="admin/dashboard" replace  /> 
                            </Switch>
                        </div> 
                    </div>
            </div>
        </div>
    );
}

export default MasterLayout;
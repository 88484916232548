import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ViewCategory() {

    const [loading, setLoading] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [value, setValue] = useState('');

    useEffect( () => {
        axios.get(`/api/view-category`).then(res => {
            if(res.data.status === 200){
                setCategoryList(res.data.category);
            }
            setLoading(false);
        }).catch();
    }, []);

    let viewcategory_HTMLTABLE = '';

    if(loading){
        return <h4>Загрузка категорий...</h4>
    }else{
        viewcategory_HTMLTABLE = 
        categoryList.filter((category) => 
            category.name.toLowerCase().includes(value) ||
            category.category_code.toLowerCase().includes(value)
            )
            .map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.category_code}</td>
                    <td>{item.slug}</td>
                    <td>{item.section.name}</td>
                    <td>{item.status == 0 ? 'Включен' : 'Выключен'}</td>
                    <td>
                        <Link to={`/admin/edit-category/${item.id}`} className="btn btn-success btn-sm">Редактировать</Link>
                    </td>
                </tr>
            )
        });
    }


    return (
        <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                    <div className=" row">
                        <div className="col-sm"><h4>Все категории</h4></div>
                        <div className="col-sm-4">
                            <form>
                                <div class="input-group">
                                    <input 
                                        type="search"
                                        className="form-control rounded"
                                        placeholder="Поиск по названию категории"
                                        aria-label="Search"
                                        aria-describedby="search-addon"
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)} 
                                    />                                    
                                </div>
                            </form>
                        </div>
                        <div className="col-sm">
                            <Link to="/admin/add-category" className="btn btn-primary btn-sm float-end">Добавить категорию</Link>
                        </div>                    
                    </div>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Код</th>
                                <th>Транслит</th>
                                <th>Секция</th>
                                <th>Статус</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewcategory_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ViewCategory;
import React from 'react'

function DeliveryPayment() {
  return (
    <div className="container-fluid px-4">
        <h1 className="mt-4 mb-5">Раздел доставка и оплата</h1>
        <form id="BRAND_FORM">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

              <div className="form-group mb-3">
                <textarea type="text" required name="name" className="form-control"></textarea>
              </div>

            </div>
          </div>
          <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
        </form>
      </div>
  )
}

export default DeliveryPayment
